import React, { useEffect, useRef } from "react";


export const useScrollingAppContainer = () => {

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current = document.querySelector('#app_scroll_container');
  }, []);

  const scrollTop = (behavior: ScrollBehavior = 'smooth') => {
    ref.current?.scrollTo({ top: 0, left: 0, behavior });
  }

  return { ref, scrollTop };
}
