import { faEye, faEyeSlash, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggle } from 'app/hooks/useToggle';
import React, { useRef } from 'react';
import { ValidatedInputProps } from 'react-jhipster';
import { Button, Col, FormFeedback, FormGroup, FormText, InputGroup, Label, UncontrolledTooltip } from 'reactstrap';
import InputWrapper from './InputWrapper';
import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { FormOptions } from './formUtils';

interface LhmInputProps<T extends FieldValues> extends LhmBaseInputProps<T> {
  hintText?: string;
  hideOptional?: boolean;
}
/**
 * Date input wizardry - when using `type=date` inside a react-hook-form, `register` will fail to insert the value when loading a form. Use `const value = watch('dateName')` and `value={value}` to emake sure it works.
 *
 * Forked from {@link ValidatedInput} to add some sauce.
 * - Adds in ability to render a delete button at end of input.
 * - Adds in ability to render other components e.g. {@link Button} or {@link InputGroupText} at start or end of input.
 * - Adds hintText below label
 * - Adds 'Optional' hint text if validate.required prop not set or is false.
 */
const LhmInput = <T extends FieldValues>({
  hintText,
  hideOptional,
  children,
  name,
  id,
  disabled,
  className,
  check,
  row,
  col,
  tag,
  label,
  labelClass,
  labelHidden,
  inputClass,
  inputTag,
  hidden,
  removeBottomMargin,
  ...attributes
}: LhmInputProps<T>) => {
  const optional = showOptionalHint(hideOptional, attributes.validate);

  const getInput = () => {
    return (
      <LhmInputBase name={name} id={id} disabled={disabled} className={inputClass} hidden={hidden} tag={inputTag} {...attributes}>
        {children}
      </LhmInputBase>
    );
  };

  const inputRow = row ? <Col {...col}>{getInput()}</Col> : getInput();

  return (
    <FormGroup check={removeBottomMargin || check} disabled={disabled} row={row} className={className} hidden={hidden} tag={tag}>
      {check && inputRow}
      {label && (
        <Label
          style={{ marginBottom: '0em' }}
          id={`${name}Label`} 
          check={check}
          htmlFor={ attributes.type === 'date' || attributes.type === "datetime-local" || attributes.type === "datetime" ? `rdp-form-control-${id??name}` : id ?? name}
          className={labelClass}
          hidden={labelHidden || hidden}
        >
          {label}
          {optional && (
            <FormText color="muted" className="ms-2">
              Optional
            </FormText>
          )}
        </Label>
      )}
      {hintText && <FormText className="d-block">{hintText}</FormText>}
      {!check && inputRow}
    </FormGroup>
  );
};

interface LhmBaseInputProps<T extends FieldValues> extends Omit<ValidatedInputProps, 'register'> {
  register?: UseFormRegister<T>; // overrides original `ValidatedInputProps.register` prop with one that ensures better type safety
  startInputAddon?: React.ReactNode; // if present renders input in an input group with component at the start
  endInputAddon?: React.ReactNode; // if present renders input in an input group with component at the end
  onDeleteRow?: () => void; // if present renders input in an input group with a delete button at the end
  maskToPattern?: (value: string) => string; // if present used to autoformat text
  removeBottomMargin?: boolean; // removes `mb-3` classname from wrapping FormGroup
}

const LhmInputBase = <T extends FieldValues>({
  onDeleteRow,
  startInputAddon,
  endInputAddon,
  maskToPattern,
  name,
  id = name,
  register,
  error,
  isTouched,
  isDirty,
  validate,
  children,
  className,
  onChange,
  onBlur,
  ...attributes
}: LhmBaseInputProps<T>) => {
  const getInput = () => {
    return (
      <InputWrapper name={name} id={id} className={className} onChange={onChange} onBlur={onBlur} {...attributes}>
        {children}
      </InputWrapper>
    );
  };

  const wrap = (input: JSX.Element) => {
    const useInputGroup = onDeleteRow || startInputAddon || endInputAddon;

    return (
      <>
        {useInputGroup ? (
          <InputGroup className={className} size={attributes.bsSize}>
            {startInputAddon}
            {input}
            {endInputAddon}
            {onDeleteRow && (
              <Button onClick={onDeleteRow} color="light" className="ms-2" data-cy={`${name}DeleteButton`} aria-label="Delete row">
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </InputGroup>
        ) : (
          input
        )}
        {error && <FormFeedback>{error.message}</FormFeedback>}
      </>
    );
  };

  if (!register) {
    return wrap(getInput());
  }

  className = className || '';
  className = isTouched ? `${className} is-touched` : className;
  className = isDirty ? `${className} is-dirty` : className;

  const { name: registeredName, onBlur: onBlurValidate, onChange: onChangeValidate, ref } = register(name, validate);
  return (
    <>
      {wrap(
        <InputWrapper
          name={registeredName}
          id={id ?? name}
          valid={isTouched && !error}
          invalid={!!error}
          innerRef={ref}
          className={className}
          onChange={e => {
            if (maskToPattern) {
              const {
                target: { selectionStart, value },
                nativeEvent,
              } = e;
              const { inputType } = nativeEvent as InputEvent;
              const newValue = maskToPattern(value);
              let cursorDelta = newValue.length - value.length;
              if (
                (cursorDelta === -1 && inputType === 'deleteContentForward') ||
                (cursorDelta === 1 && inputType === 'deleteContentBackward')
              )
                cursorDelta = 0;
              const newCursorPos = Math.max(0, selectionStart + cursorDelta);
              e.target.value = newValue;
              e.target.setSelectionRange(newCursorPos, newCursorPos);
            }
            void onChangeValidate(e);

            onChange && onChange(e);
          }}
          onBlur={e => {
            void onBlurValidate(e);
            onBlur && onBlur(e);
          }}
          {...attributes}
        >
          {children}
        </InputWrapper>
      )}
    </>
  );
};

const LhmPasswordInput = <T extends FieldValues>(props: LhmInputProps<T>) => {
  const [showPassword, toggle] = useToggle(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const eyeButton = (
    <>
      <Button innerRef={buttonRef} type="button" onClick={toggle} color="light" aria-label={`${showPassword ? 'Hide' : 'Show'} password`}>
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} fixedWidth />
      </Button>
      <UncontrolledTooltip target={buttonRef}>{`${showPassword ? 'Hide' : 'Show'} password`}</UncontrolledTooltip>
    </>
  );

  return <LhmInput {...props} type={showPassword ? 'text' : 'password'} endInputAddon={eyeButton} />;
};

interface LhmChoiceInputsProps<T, U> extends LhmInputProps<T> {
  options: FormOptions<U>[];
  type: 'checkbox' | 'radio';
  horizontal?: boolean;
}

export const LhmChoiceInputs = <T extends FieldValues, U extends string>({ type, options, horizontal, hintText, hideOptional, ...props }: LhmChoiceInputsProps<T, U>) => {
  const optional = showOptionalHint(hideOptional, props.validate);
  return (
    <fieldset className='mb-4'>
      {props.label && (
        <legend className='form-label'>
          {props.label}
          {optional && (
            <FormText color="muted" className="ms-2">
              Optional
            </FormText>
          )}
        </legend>
      )}
      {hintText && <FormText className="d-block">{hintText}</FormText>}
      {options.map(o => {
        return (
          <LhmInput
            {...props}
            className={`form-check ms-3 ${horizontal ? 'form-check-inline mt-2' : 'mt-1'}`}
            error={undefined}
            valid={!props.error && props.isTouched}
            invalid={!!props.error}
            key={o.displayName}
            type={type}
            hideOptional
            value={o.value}
            label={o.displayName}
            id={`${o.value}-${props.name}`}
            removeBottomMargin
            data-cy={o.datacy}
          />
        );

      })}
      {props.error && <FormFeedback className='d-block'>{props.error.message}</FormFeedback>}
    </fieldset>
  );
}

// give it names so it works with ValidatedForm
LhmInput.displayName = 'ValidatedField';
LhmInputBase.displayName = 'ValidatedInput';
LhmPasswordInput.displayName = 'ValidatedField';
LhmChoiceInputs.displayName = 'ValidatedField';
export { LhmInput, LhmInputBase, LhmPasswordInput };

const showOptionalHint = (forceHide: boolean, validateOptions?: RegisterOptions) => {
  return forceHide ?
    false :
    validateOptions?.required === undefined || JSON.stringify(validateOptions.required).includes('false');
}
