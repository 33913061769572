import React, {ReactChild, ReactChildren, useEffect} from 'react';
import {Container} from "reactstrap";
import {Header} from 'app/shared/layout/navigation/header/Header';
import ErrorBoundary from "app/shared/error/error-boundary";
import {Sidebar} from "app/shared/layout/navigation/sidebar/Sidebar";
import Footer from "app/shared/layout/footer/footer";
import {useResponsiveSidebar} from "app/hooks/useResponsiveSidebar";
import classNames from "classnames";
import { useLocation } from 'react-router-dom';
import { useScrollingAppContainer } from 'app/hooks/useScrollingAppContainer';

interface Props {
  children: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
}

export const Navigation = ({
                             children,
                             isAuthenticated,
                             isAdmin,
                             isOpenAPIEnabled,
                             isInProduction,
                             ribbonEnv
                           }: Props) => {

  const [isOpen, toggleIsOpen, isMobile] = useResponsiveSidebar();
  const location = useLocation();
  const { scrollTop } = useScrollingAppContainer();
  useEffect(() => {
    scrollTop('auto');
  }, [location.pathname]);

  return (
    <>
      {isAuthenticated && (
        <ErrorBoundary>
          <Sidebar
            isOpen={isOpen}
            isMobile={isMobile}
            toggle={toggleIsOpen}
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled}
          />
        </ErrorBoundary>
      )}
      <Container
        fluid
        className={classNames('w-100 vh-100 mh-100 overflow-hidden p-0 d-flex flex-column',
          {'d-none': isMobile && isOpen}
        )}
      >
        <ErrorBoundary>
          <Header
            toggle={toggleIsOpen}
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
          />
        </ErrorBoundary>
        <div className={classNames('vh-100 overflow-auto d-flex flex-column')} id='app_scroll_container'>
          <main className='p-0 flex-grow-1'>
            {children}
          </main>
          <Footer/>
        </div>
      </Container>
    </>
  );
}
