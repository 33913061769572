import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "app/config/store";
import { getEntity as getFarm, resetEntity as clearFarm } from "app/entities/organisation/farm.reducer";
import { useSessionStorage } from "app/hooks/useStorage";
import { getEntityByOrganisation } from "app/entities/membership/membership.reducer";
import { getAccessList } from "app/shared/reducers/access.reducer";
import { setFarmId } from "app/shared/reducers/app-config.reducer";
import { useIsAdmin } from "app/hooks/useIsAdmin";


export const FarmSwitcher = () => {

  const dispatch = useAppDispatch();
  const [storedId, setStoredId] = useSessionStorage<number>('farm_id', -1);
  const farm = useAppSelector(state => state.farm.entity);
  const { farmId, refetchFarmCounter } = useAppSelector(state => state.appConfig);

  const farmUpdateSuccess = useAppSelector(state => state.farm.updateSuccess);
  const membershipUpdateSuccess = useAppSelector(state => state.membership.updateSuccess);
  const vetPracticeUpdateSuccess = useAppSelector(state => state.vetPractice.updateSuccess);
  const consultantUpdateSuccess = useAppSelector(state => state.consultancy.updateSuccess);

  const accessLoading = useAppSelector(state => state.access.loading);
  const access = useAppSelector(state => state.access.entities);

  const [isAdmin, isSuperView] = useIsAdmin();


  const farmList = useMemo(() => {
    return access.filter(a => a.isFarm && a.isMembershipActive);
  }, [access]);

  const switchToFarm = (id: number) => {
    setStoredId(id);
    if (farm.id === id || id < 1) return;
    dispatch(getFarm(id));
  }

  // fetch the membership to the farm
  useEffect(() => {
    if (!farm.id) return;
    dispatch(getEntityByOrganisation(farm.id));
  }, [farm]);

  // keep farmId state in sync with session storage
  useEffect(() => {
    dispatch(setFarmId(storedId));
  }, [storedId]);

  // refetch access list when access related things change (refetch token, memberships, etc)
  useEffect(() => {
    if (accessLoading) return;
    dispatch(getAccessList());
  }, [refetchFarmCounter, farmUpdateSuccess, membershipUpdateSuccess, vetPracticeUpdateSuccess, consultantUpdateSuccess]);

  // re-fetch the farm entity when the refetch counter changes
  useEffect(() => {
    if (!farm.id) return;
    dispatch(getFarm(farm.id));
  }, [refetchFarmCounter]);

  // select a farm to switch to
  useEffect(() => {
    if ((isAdmin && isSuperView) || farmList.some(a => a.orgId === farmId)) {
      // we have access to the farm we want, download it.
      switchToFarm(farmId);
    } else {
      // we don't have access to the farm we want, clear the farm store
      dispatch(clearFarm());
      if (farmList.length === 0) return;
      // we have a farm we can switch to, so switch to it
      switchToFarm(farmList[0].orgId);
    }
  }, [isAdmin, isSuperView, farmId, farmList]);

  // this component doesn't show anything.
  return null;
}
