import { IForm } from 'app/shared/model/form.model';

export interface IFormData {
  id?: number;
  formData?: string;
  index?: number;
  form?: IForm;
}

export const defaultValue: Readonly<IFormData> = {};
