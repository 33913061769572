import { ITemplate } from 'app/shared/model/template.model';

export interface IJsonSchema {
  id?: number;
  schema?: string;
  uiSchema?: string;
  index?: number;
  title?: string;
  defaultData?: string;
  template?: ITemplate;
}

export const defaultValue: Readonly<IJsonSchema> = {};
