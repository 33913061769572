import { IOrganisation } from 'app/shared/model/organisation.model';
import { IForm } from 'app/shared/model/form.model';
import { FormType } from 'app/shared/model/enumerations/form-type.model';

export interface ICustomTemplate {
  id?: number;
  version?: number;
  createdDate?: string;
  formType?: FormType;
  obsolete?: boolean;
  available?: boolean;
  title?: string;
  organisation?: IOrganisation;
  form?: IForm;
}

export const defaultValue: Readonly<ICustomTemplate> = {
  obsolete: false,
  available: false,
};
