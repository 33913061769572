import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAppSelector } from 'app/config/store';
import { useIsAdmin } from 'app/hooks/useIsAdmin';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { UserRole } from '../model/enumerations/user-role.model';
import { LoadingSpinner } from '../error/LoadingSpinner';

interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];
  hasAnyRole?: UserRole[];
}

export const PrivateRouteComponent = ({ component: Component, hasAnyAuthorities = [], hasAnyRole = [], ...rest }: IOwnProps) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const sessionHasBeenFetched = useAppSelector(state => state.authentication.sessionHasBeenFetched);
  const account = useAppSelector(state => state.authentication.account);
  const appUser = useAppSelector(state => state.appUser.entity);
  const [isAdmin] = useIsAdmin();
  const isAuthorized = hasAnyAuthority(account.authorities, hasAnyAuthorities);
  const hasCorrectRole = isAdmin || hasRole(appUser.primaryRole, hasAnyRole);

  const isLoaded = useAppSelector(state => state.loadingBar?.default === 0)

  const checkAuthorities = props => {
    if (isAuthorized && hasCorrectRole) {
      return (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      );
    }
    if (!isLoaded) {
      return <LoadingSpinner />;
    }
    return (
      <div className="insufficient-authority">
        <div className="alert alert-danger">You are not authorized to access this page.</div>
      </div>
    );
  }

  const renderRedirect = props => {
    if (!sessionHasBeenFetched) {
      return <div></div>;
    } else {
      return isAuthenticated ? (
        checkAuthorities(props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            search: props.location.search,
            state: { from: props.location },
          }}
        />
      );
    }
  };

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);

  return <Route {...rest} render={renderRedirect} />;
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

export const hasRole = (userRole: UserRole, allowableRoles: UserRole[]) => {
  if (!userRole) return false;
  if (allowableRoles.length === 0) return true;
  return allowableRoles.includes(userRole);
}

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */
export default PrivateRouteComponent;
