import dayjs from 'dayjs';
import { IForm } from 'app/shared/model/form.model';
import { UserRole } from 'app/shared/model/enumerations/user-role.model';
import { FormState } from 'app/shared/model/enumerations/form-state.model';

export interface IApproval {
  id?: number;
  userRole?: UserRole;
  userName?: string;
  timestamp?: string;
  formState?: FormState;
  message?: string | null;
  form?: IForm;
}

export const defaultValue: Readonly<IApproval> = {};
