import { IOrganisation } from 'app/shared/model/organisation.model';

export interface IConsultancy {
  id?: number;
  name?: string;
  address_1?: string;
  address_2?: string | null;
  area?: string | null;
  town?: string;
  county?: string;
  postcode?: string;
  tel?: string;
  email?: string;
  webPage?: string | null;
  organisation?: IOrganisation | null;
}

export const defaultValue: Readonly<IConsultancy> = {};
