import {
  AnyAction,
  AsyncThunk,
  ActionReducerMapBuilder,
  createSlice,
  SerializedError,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { logoutSession } from './authentication';
import { IRootState } from 'app/config/store';

/**
 * Model for redux actions with pagination
 */
export type IQueryParams = { query?: string; page?: number; size?: number; sort?: string };

/**
 * Useful types for working with actions
 */
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
export type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

/**
 * Check if the async action type is rejected
 */
export function isRejectedAction(action: AnyAction) {
  return action.type.endsWith('/rejected');
}

/**
 * Check if the async action type is pending
 */
export function isPendingAction(action: AnyAction) {
  return action.type.endsWith('/pending');
}

/**
 * Check if the async action type is completed
 */
export function isFulfilledAction(action: AnyAction) {
  return action.type.endsWith('/fulfilled');
}

const commonErrorProperties: Array<keyof SerializedError> = ['name', 'message', 'stack', 'code'];

/**
 * serialize function used for async action errors,
 * since the default function from Redux Toolkit strips useful info from axios errors
 */
export const serializeAxiosError = (value: any): AxiosError | SerializedError => {
  if (typeof value === 'object' && value !== null) {
    if (value.isAxiosError) {
      return value;
    } else {
      const simpleError: SerializedError = {};
      for (const property of commonErrorProperties) {
        if (typeof value[property] === 'string') {
          simpleError[property] = value[property];
        }
      }

      return simpleError;
    }
  }
  return { message: String(value) };
};

export interface EntityState<T> {
  loading: boolean;
  errorMessage: string | null;
  entities: ReadonlyArray<T>;
  entity: T;
  links?: any;
  updating: boolean;
  totalItems?: number;
  updateSuccess: boolean;
  headers?: { [key: string]: any }
}

export interface PaginatedEntityState<T> {
  loading: boolean;
  errorMessage: string | null;
  entities: Readonly<{[id: number]: T}>;
  entity: T;
  links?: any;
  updating: boolean;
  totalItems?: number;
  updateSuccess: boolean;
  currentPage: number;
  totalUnread?: number;
  pageSize: number;
  pages: Readonly<{[page: number]: number[]}>;
  headers?: { [key: string]: any }
}

/**
 * A wrapper on top of createSlice from Redux Toolkit to extract
 * common reducers and matchers used by entities
 */
export const createEntitySlice = <T, Reducers extends SliceCaseReducers<EntityState<T>>>({
  name = '',
  initialState,
  reducers,
  extraReducers,
  skipRejectionHandling,
}: {
  name: string;
  initialState: EntityState<T>;
  reducers?: ValidateSliceCaseReducers<EntityState<T>, Reducers>;
  extraReducers?: (builder: ActionReducerMapBuilder<EntityState<T>>) => void;
  skipRejectionHandling?: boolean;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      /** set the entity to the payload object */
      setEntity(state, action: PayloadAction<T | undefined>) {
        return {
          ...state,
          entity: action.payload,
        }
      },
      /**
       * Reset the entity state to initial state
       */
      reset() { // resets everything
        return initialState;
      },
      resetEntity(state) { // resets just the entity state
        return {
          ...state,
          entity: initialState.entity,
        }
      },
      resetEntities(state) {
        return {
          ...state,
          entities: initialState.entities,
        }
      },
      resetAllEntity(state) { // resets the entity and entities state
        return {
          ...state,
          entity: initialState.entity,
          entities: initialState.entities,
        }
      },
      ...reducers,
    },
    extraReducers(builder) {
      // reset all reducers created with this wrapper on logout
      builder.addCase(logoutSession, () => {
        return { ...initialState }
      })
      extraReducers(builder);
      /*
       * Common rejection logic is handled here.
       * If you want to add your own rejection logic, pass `skipRejectionHandling: true`
       * while calling `createEntitySlice`
       * */
      if (!skipRejectionHandling) {
        builder.addMatcher(isRejectedAction, (state, action) => {
          state.loading = false;
          state.updating = false;
          state.updateSuccess = false;
          state.errorMessage = action.error.message;
        });
      }
    },
  });
};

/**
 * A wrapper on top of createSlice from Redux Toolkit to extract
 * common reducers and matchers used by entities
 * For paginated store
 */
export const createPaginatedEntitySlice = <T, Reducers extends SliceCaseReducers<PaginatedEntityState<T>>>({
  name = '',
  initialState,
  reducers,
  extraReducers,
  skipRejectionHandling,
}: {
  name: string;
  initialState: PaginatedEntityState<T>;
  reducers?: ValidateSliceCaseReducers<PaginatedEntityState<T>, Reducers>;
  extraReducers?: (builder: ActionReducerMapBuilder<PaginatedEntityState<T>>) => void;
  skipRejectionHandling?: boolean;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      /**
       * Reset the entity state to initial state
       */
      reset() {
        return initialState;
      },
      ...reducers,
    },
    extraReducers(builder) {
      // reset all reducers created with this wrapper on logout
      builder.addCase(logoutSession, () => {
        return { ...initialState }
      })
      extraReducers(builder);
      /*
       * Common rejection logic is handled here.
       * If you want to add your own rejection logic, pass `skipRejectionHandling: true`
       * while calling `createEntitySlice`
       * */
      if (!skipRejectionHandling) {
        builder.addMatcher(isRejectedAction, (state, action) => {
          state.loading = false;
          state.updating = false;
          state.updateSuccess = false;
          state.errorMessage = action.error.message;
        });
      }
    },
  });
};

/**
 * Utility method to run a function following a successful axios request within a redux dispatch.
 * 
 * Usage: `dispatch(someAction()).then(onSuccessfulDispatch(someMethod));`
 * @param onSuccess Optional Void function that will be called if the axios request status is 'fulfilled';
 */
export const onSuccessfulDispatch = (onSuccess: (r?: any) => void | undefined) => (r: any) => r.meta.requestStatus === 'fulfilled' && onSuccess?.(r);

/**
 * Utility to convert `thunkAPI` provided by `createAsyncThunk` to the correct type
 * @param thunkAPI the thunkAPI provided by {@link createAsyncThunk} with type `GetThunkAPI<{}>`
 * @returns the reducer state correctly typed
 */
export const getThunkState = (thunkAPI: any): IRootState => (thunkAPI.getState() as IRootState);


export const replaceInEntities = <T extends { id?: number }>(item: T, entities: T[]) => {
  return entities.map((entity) => entity?.id === item?.id && item?.id !== undefined ? item : entity);
}