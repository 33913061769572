import dayjs from 'dayjs';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { IAppUser } from 'app/shared/model/app-user.model';
import { IAction } from 'app/shared/model/action.model';
import { EventType } from 'app/shared/model/enumerations/event-type.model';
import { Recurring } from 'app/shared/model/enumerations/recurring.model';
import { Priority } from 'app/shared/model/enumerations/priority.model';
import { EventStatus } from 'app/shared/model/enumerations/event-status.model';

export interface IEvent {
  id?: number;
  title?: string;
  contents?: string | null;
  type?: EventType;
  startTime?: string;
  endTime?: string | null;
  location?: string | null;
  recurring?: Recurring;
  priority?: Priority | null;
  status?: EventStatus;
  previous?: IEvent | null;
  organisation?: IOrganisation;
  users?: IAppUser[] | null;
  creator?: IAppUser | null;
  attendingOrganisations?: IOrganisation[] | null;
  action?: IAction | null;
}

export const defaultValue: Readonly<IEvent> = {};
