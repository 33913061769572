import { FieldValues } from "react-hook-form";
import { StockType } from "../enumerations/stock-type.model";


interface Base {
  name: string;
  hsMember?: boolean;
  notes?: string;
  id?: string | number;
}
interface HasValue extends Base {
  value: {
    stockNumber: number;
    stockType: StockType;
    id: string | number;
  }
  children?: never;
}
interface HasChildren extends Base {
  value?: never;
  children: IAnimalGroup[];
}

export type IAnimalGroup = HasValue | HasChildren;


export interface AnimalGroupsCombined extends FieldValues {
  name?: string;
  type: string;
  hsMember?: boolean;
  notes?: string;
  id?: number | string;
  archived?: boolean;
  tags?:string;
  values?: {
    [key in StockType]?: {
      value: number;
      id?: number | string;
    };
  }
}
export interface AnimalGroupsForm {
  herds: AnimalGroupsCombined[]
}

export const defaultValue: AnimalGroupsForm = {
  herds: [],
}
