import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';
import { logoutSession } from 'app/shared/reducers/authentication';
import { isRejectedAction, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { SubscriptionStatus } from 'app/shared/model/dto/stripe/SubscriptionStatus.model';
import { IOrganisation } from 'app/shared/model/organisation.model';


export interface StripeState {
  subscriptionStatus: SubscriptionStatus;
  loading: boolean;
  errorMessage?: string;
}

const initialState: StripeState = {
  subscriptionStatus: {
    customerId: null,
    setupIntentId: null,
    setupIntentStatus: null,
    clientSecret: null,
    paymentId: null,
    subscriptionId: null,
    subscriptionDetails: {
      startDate: null,
      nextBillingDate: null,
    }
  },
  loading: false,
}

const apiUrl = 'api/stripe';

export const checkAccount = createAsyncThunk(
  'stripe/setUpCustomer',
  async ({ vetPractice }: { vetPractice: IOrganisation }) => {
    const requestUrl = `${apiUrl}/check-account`;
    return axios.post<SubscriptionStatus>(requestUrl, vetPractice);
  },
  { serializeError: serializeAxiosError }
);

export const subscribe = createAsyncThunk(
  'stripe/subscribe',
  async ({ subscriptionStatus }: { subscriptionStatus: SubscriptionStatus }) => {
    const requestUrl = `${apiUrl}/subscribe`;
    return axios.post<SubscriptionStatus>(requestUrl, subscriptionStatus);
  },
  { serializeError: serializeAxiosError }
);
export const StripeConfigSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(logoutSession, () => {
      return initialState;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.errorMessage = action.error.message;
      state.loading = false;
    });
    builder.addMatcher(isFulfilled(checkAccount, subscribe), (state, action) => {
      state.subscriptionStatus = action.payload.data;
      state.loading = false;
    });
    builder.addMatcher(isPending(checkAccount, subscribe), (state) => {
      state.loading = true;
      state.errorMessage = null;
    });
  },
})

export const { reset } = StripeConfigSlice.actions;

export default StripeConfigSlice.reducer;
