import { IOrganisation } from 'app/shared/model/organisation.model';
import { IDocument } from 'app/shared/model/document.model';

export interface ICompetency {
  id?: number;
  task?: string;
  staff?: string;
  details?: string | null;
  farm?: IOrganisation | null;
  documents?: IDocument[] | null;
}

export const defaultValue: Readonly<ICompetency> = {};
