import dayjs from 'dayjs';
import { IJsonSchema } from 'app/shared/model/json-schema.model';
import { IWorkflowStep } from 'app/shared/model/workflow-step.model';
import { IDefaultActions } from 'app/shared/model/default-actions.model';
import { IForm } from 'app/shared/model/form.model';
import { FormType } from 'app/shared/model/enumerations/form-type.model';

export interface ITemplate {
  id?: number;
  formType?: FormType;
  name?: string;
  author?: string;
  version?: string;
  createdDate?: string;
  renderTemplate?: string;
  schemaOrder?: string;
  schemata?: IJsonSchema[] | null;
  workflowSteps?: IWorkflowStep[] | null;
  defaultAction?: IDefaultActions | null;
  forms?: IForm[] | null;
  pageTitles?: { [schemaIndex: number]: string } | null
}

export const defaultValue: Readonly<ITemplate> = {};
