import { UserRole } from "../enumerations/user-role.model";

export interface FarmUserDto {
  type: 'ORGANISATION' | 'USER';
  id: number;
  name: string;
  role: UserRole;
  active: boolean;
}

export const defaultValue: FarmUserDto = {
  type: null,
  id: null,
  name: null,
  role: null,
  active: false,
}
