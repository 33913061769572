import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IAnimalGroup, defaultValue, AnimalGroupsForm } from 'app/shared/model/dto/AnimalGroup.model';
import { refetchFarm } from 'app/shared/reducers/app-config.reducer';

const initialState: EntityState<AnimalGroupsForm> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/animal-group';

// Actions

export const getEntity = createAsyncThunk(
  'animalGroup/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/farm/${id}`;
    return axios.get<AnimalGroupsForm>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'animalGroup/create_entity',
  async ({entity, id}: {entity: AnimalGroupsForm, id: number | string}, thunkAPI) => {
    const result = await axios.post<AnimalGroupsForm>(`${apiUrl}/farm/${id}`, cleanEntity(entity));
    thunkAPI.dispatch(refetchFarm());
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const AnimalGroupSlice = createEntitySlice({
  name: 'animalGroup',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = AnimalGroupSlice.actions;

// Reducer
export default AnimalGroupSlice.reducer;
