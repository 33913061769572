import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICompetency, defaultValue } from 'app/shared/model/competency.model';

const initialState: EntityState<ICompetency> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/competencies';

// Actions

export const getCompetenciesForFarm = createAsyncThunk(
  'competency/fetch_by_farm',
  async ({ farmId }: { farmId: number }) => {
    const requestUrl = `${apiUrl}/farm/${farmId}`;
    return axios.get<ICompetency[]>(requestUrl);
  }
);

export const createCompetencyForFarm = createAsyncThunk(
  'competency/create_for_farm',
  async ({ entity, farmId }: { entity: ICompetency, farmId: number }, thunkAPI) => {
    const result = await axios.post<ICompetency>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getCompetenciesForFarm({ farmId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateCompetencyForFarm = createAsyncThunk(
  'competency/update_for_farm',
  async ({ entity, farmId }: { entity: ICompetency, farmId: number }, thunkAPI) => {
    const requestUrl = `${apiUrl}/${entity.id}`;
    const result = await axios.put<ICompetency>(requestUrl, cleanEntity(entity));
    thunkAPI.dispatch(getCompetenciesForFarm({ farmId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteCompetency = createAsyncThunk(
  'competency/delete_for_farm',
  async ({ entityId, farmId }: { entityId: number, farmId: number }, thunkAPI) => {
    const requestUrl = `${apiUrl}/${entityId}`;
    const result = await axios.delete<ICompetency>(requestUrl);
    thunkAPI.dispatch(getCompetenciesForFarm({ farmId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);
// slice

export const CompetencySlice = createEntitySlice({
  name: 'competency',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteCompetency.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getCompetenciesForFarm), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createCompetencyForFarm, updateCompetencyForFarm), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getCompetenciesForFarm), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createCompetencyForFarm, updateCompetencyForFarm, deleteCompetency), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CompetencySlice.actions;

// Reducer
export default CompetencySlice.reducer;
