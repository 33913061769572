import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  email: string | undefined;
  text?: string | JSX.Element;
  hideIcon?: boolean;
}

export const MailTo = ({ email, text, hideIcon }: Props) => {
  if (!email) return text ? <>{text}</> : null;

  return (
    <a href={`mailto:${email}`}>
      {!hideIcon && <FontAwesomeIcon icon={faEnvelope} className='me-2' size='sm' />}
      {text ?? email}
    </a>
  );
}
