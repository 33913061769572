import React from 'react';
import {Redirect, RouteComponentProps} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'app/config/store';
import {login} from 'app/shared/reducers/authentication';
import LoginForm from "app/modules/login/login-form";
import {UnauthenticatedPageWrapper} from "app/modules/account/UnauthenticatedPageWrapper";

export const Login = (props: RouteComponentProps<any>) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loginError = useAppSelector(state => state.authentication.loginError);

  const handleLogin = (username, password, rememberMe = false) => dispatch(login(username?.toLowerCase(), password, rememberMe));

  const {location} = props;
  const {from} = (location.state as any) || {from: {pathname: '/', search: location.search}};
  if (isAuthenticated) {
    return <Redirect to={from}/>;
  }

  return (
    <UnauthenticatedPageWrapper imgSrc='/content/images/hero/cow-and-calf.jpg' imgAlt='Cows on a hillside'>
      <LoginForm loginError={loginError} handleLogin={handleLogin}/>
    </UnauthenticatedPageWrapper>
  );
};

export default Login;
