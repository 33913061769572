import { ITemplate } from 'app/shared/model/template.model';
import { JobRole } from 'app/shared/model/enumerations/job-role.model';

export interface IWorkflowStep {
  id?: number;
  role?: JobRole;
  completion?: boolean | null;
  review?: boolean | null;
  approval?: boolean | null;
  template?: ITemplate;
}

export const defaultValue: Readonly<IWorkflowStep> = {
  completion: false,
  review: false,
  approval: false,
};

export type WorkflowStepType = keyof Omit<IWorkflowStep, 'id' | 'role' | 'template'>;
