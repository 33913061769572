import { FormType } from "app/shared/model/enumerations/form-type.model";

interface Context {
  path: string[];
}

interface TypeContext extends Context {
  type: string;
  formType?: never;
  formId?: never;
  formGroupId?: never;
  farmId?: never;
}

interface FormTypeContext extends Context {
  type?: never;
  formType: FormType;
  formId?: never;
  formGroupId?: never;
  farmId?: never;
}

interface FormContext extends Context {
  type?: string;
  formType?: never;
  formId: string| number;
  formGroupId?: never;
  farmId?: never;
}

interface FormGroupContext extends Context {
  type?: never;
  formType?: never;
  formId?: never;
  formGroupId: string| number;
  farmId?: never;
}

interface FarmContext extends Context {
  type?: never;
  formType?: never;
  formId?: never;
  formGroupId?: never;
  farmId: string | number;
}

export type LibraryContext = TypeContext | FormTypeContext | FormContext | FormGroupContext | FarmContext;

export interface BrowserItem {
  id: string | number;
  title: string;
  count: number;
}

export enum DocumentType {
  POLICIES = 'POLICIES',
  PROCEDURES = 'PROCEDURES',
  RISK_ASSESSMENTS = 'RISK_ASSESSMENTS',
  PROTOCOLS__VET = 'PROTOCOLS__VET',
  PROTOCOLS__NUTRITION = 'PROTOCOLS__NUTRITION',
  PROTOCOLS__TREATMENTS = 'PROTOCOLS__TREATMENTS',
  TEST_RESULTS = 'TEST_RESULTS',
  CORRESPONDENCE = 'CORRESPONDENCE',
  CONSUMABLES = 'CONSUMABLES',
  PHARMACY_RECORDS = 'PHARMACY_RECORDS',
  OTHER = 'OTHER',
  MODULE_RECORD = "MODULE_RECORD",
  BIOSECURITY_POLICY = 'BIOSECURITY_POLICY',
  HEALTH_PLAN_SUMMARY = 'HEALTH_PLAN_SUMMARY',
}

export const documentCategories = [
  DocumentType.POLICIES,
  DocumentType.PROCEDURES,
  DocumentType.RISK_ASSESSMENTS,
  'PROTOCOLS',
  DocumentType.TEST_RESULTS,
  DocumentType.CORRESPONDENCE,
  DocumentType.CONSUMABLES,
  DocumentType.PHARMACY_RECORDS,
  DocumentType.OTHER,
  DocumentType.MODULE_RECORD,
  DocumentType.BIOSECURITY_POLICY,
  DocumentType.HEALTH_PLAN_SUMMARY,
].sort();

export const preventUploadDocumentTypes: string[] = [
  DocumentType.MODULE_RECORD,
  DocumentType.HEALTH_PLAN_SUMMARY,
]
export const preventUploadFormTypes: string[] = [
  FormType.FARM_POLICIES,
]
