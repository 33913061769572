import { IUser } from 'app/shared/model/user.model';
import { IMembership } from 'app/shared/model/membership.model';
import { IEvent } from 'app/shared/model/event.model';
import { IPreferences } from 'app/shared/model/preferences.model';
import { UserRole } from 'app/shared/model/enumerations/user-role.model';

export interface IAppUser {
  id?: number;
  primaryRole?: UserRole;
  contactPhone?: string | null;
  contactMobile?: string | null;
  contactAddress?: string | null;
  user?: IUser;
  memberships?: IMembership[] | null;
  calendarEvents?: IEvent[] | null;
  wholeName?: string | null;
  preferences?: IPreferences;
}

export const defaultValue: Readonly<IAppUser> = {};
