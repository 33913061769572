import React, {useRef, useLayoutEffect} from 'react';
import {useWindowDimensions} from "app/hooks/useWindowDimensions";
import { toggleSidebar } from 'app/shared/reducers/app-config.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const useResponsiveSidebar = (widthLimit = 768): [boolean, () => void, boolean] => {
  const dispatch = useAppDispatch();
  const {width} = useWindowDimensions();
  const isOpen = useAppSelector(state => state.appConfig.sidebarOpen);
  const isMobileRef = useRef(width <= widthLimit)
  const previousWidth = useRef(-1);

  const updateWidth = () => {
    isMobileRef.current = width <= widthLimit;
    const wasMobile = previousWidth.current <= widthLimit;

    if (isMobileRef.current !== wasMobile) {
      dispatch(toggleSidebar(!isMobileRef.current));
    }

    previousWidth.current = width;
  }

  const toggle = () => {
    dispatch(toggleSidebar());
  }

  useLayoutEffect(updateWidth, [width]);

  return [isOpen, toggle, isMobileRef.current];
}
