import dayjs, { PluginFunc } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';

// jhipster-needle-i18n-language-dayjs-imports - JHipster will import languages from dayjs here

export const customFromNowPlugin: PluginFunc = (_option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.fromNowDateOnly = function () {
    const now = dayjsFactory();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const targetDate = this;

    const diffYears = now.diff(targetDate, 'year');
    const diffMonths = now.diff(targetDate, 'month');
    const diffWeeks = now.diff(targetDate, 'week');
    const diffDays = now.diff(targetDate, 'day');
    
    if (diffYears >= 1) {
      return diffYears === 1 ? 'last year' : `${diffYears} years ago`;
    }
    if (diffMonths >= 1) {
      return diffMonths === 1 ? 'last month' : `${diffMonths} months ago`;
    }
    if (diffWeeks >= 1) {
      return diffWeeks === 1 ? 'last week' : `${diffWeeks} weeks ago`;
    }
    if (diffDays === 1) {
      return 'yesterday';
    }
    if (diffDays === 0) {
      return 'today';
    }
    return `${diffDays} days ago`;
  }
}


// DAYJS CONFIGURATION
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(customFromNowPlugin);
