import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import { getUrlParameter } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { activateAction, reset } from './activate.reducer';
import {UnauthenticatedPageWrapper} from "app/modules/account/UnauthenticatedPageWrapper";

const successAlert = (
  <Alert color="success">
    <span>
    <strong>Your user account has been activated.</strong> Please <Link to="/login" className="alert-link">sign in</Link>.
    </span>
  </Alert>
);

const failureAlert = (
  <Alert color="danger">
    <span>
      <strong>Your user could not be activated.</strong> Please use the <Link to='/account/register'>registration form</Link> to sign up.
    </span>
  </Alert>
);

export const ActivatePage = (props: RouteComponentProps<{ key: any }>) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const key = getUrlParameter('key', props.location.search);
    dispatch(activateAction(key));
    return () => {
      dispatch(reset());
    };
  }, []);

  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  return (
    <UnauthenticatedPageWrapper noBack imgSrc='/content/images/hero/highland-cow.jpg' imgAlt='A Highland cow on a rocky hillside'>
      <h2>Activation</h2>
      <Row className="justify-content-center">
        <Col md="12" className='align-items-center'>
          {activationSuccess ? successAlert : undefined}
          {activationFailure ? failureAlert : undefined}
        </Col>
      </Row>
    </UnauthenticatedPageWrapper>
  );
};

export default ActivatePage;
