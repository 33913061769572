import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Props {
  url: string | undefined;
  text?: string;
  hideIcon?: boolean;
}

export const WebLink = ({ url, text, hideIcon }: Props) => {
  if (!url) return null;

  const getUrl = (webPage: string) => {
    return webPage.startsWith('http') ? webPage : `//${webPage}`;
  };
  return (
    <>
      <a href={getUrl(url)} target="_blank" rel="noopener noreferrer">{text ?? url}</a>
      {!hideIcon && <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='ms-1' size='sm' />}
    </>
  );
}
