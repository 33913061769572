import React from 'react';
import { NavbarBrand } from "reactstrap";
import { NavLink as Link } from "react-router-dom";
import { useIsAdmin } from 'app/hooks/useIsAdmin';

export const renderDevRibbon = (isInProduction: boolean) =>
  isInProduction === false ? (
    <div className="ribbon dev">
      <span>Development</span>
    </div>
  ) : null;

export const BrandIcon = props => {
  return <img src='content/images/logo/HerdPlan_Logo_Icon.svg' alt='HerdPlan logo' style={{ width: '46px', height: '46px' }} />
}

export const Brand = props => {
  return (
    <NavbarBrand tag={Link} to="/" className="brand-logo flex-grow-1 justify-content-center align-items-center">
      <BrandIcon />
      <img src='content/images/logo/HerdPlan_Logo_Typographic.svg' alt='HerdPlan' style={{ height: '30px' }} />
    </NavbarBrand>
  );
}

export const RenderSuperViewRibbon = () => {
  const [isAdmin, isSuperView] = useIsAdmin();
  if (!(isAdmin && isSuperView)) return null;
  return (
    <div className="ribbon" id='admin-view-ribbon'>
      <span>Admin View</span>
    </div>
  );
}

interface SidebarSeparatorProps {
  removeBorder?: boolean;
  title: string;
  children: React.ReactNode;
}

export const SidebarSeparator = ({ removeBorder, title, children }: SidebarSeparatorProps) => {

  if (title === '' || !children || removeBorder) return (
    <>
      {children}
    </>
  );

  return (
    <div className='p-0 m-1 py-2 pb-0 mb-2 border-3 border-end border-bottom rounded-end shadow-sm'>
      <small 
        className='text-muted ps-2'
        style={{marginBottom: '-1em'}}
      >
        {title}
      </small>
      <div className='border-1 border-start'>
        {children}
      </div>
    </div>
  );
}