import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IFormGroup, defaultValue } from 'app/shared/model/form-group.model';
import { getFormsByOrganisation } from '../form/form.reducer';

const initialState: EntityState<IFormGroup> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/form-groups';

// Actions

export const getEntities = createAsyncThunk(
  'formGroup/fetch_entity_list',
  async ({id}: {id?: string|number}) => {
    const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}${id === undefined ? '' : `&organisationId=${id}`}`;
    return axios.get<IFormGroup[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'formGroup/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IFormGroup>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'formGroup/create_entity',
  async (entity: IFormGroup, thunkAPI) => {
    const result = await axios.post<IFormGroup>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'formGroup/update_entity',
  async (entity: IFormGroup, thunkAPI) => {
    const result = await axios.put<IFormGroup>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'formGroup/partial_update_entity',
  async (entity: IFormGroup, thunkAPI) => {
    const result = await axios.patch<IFormGroup>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'formGroup/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IFormGroup>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createFormGroup = createAsyncThunk(
  'formGroup/create',
  async (formGroup: IFormGroup, thunkAPI) => {
    const id = formGroup.organisation.id;
    const requestUrl = `${apiUrl}/organisation/${id}/create`;
    const result = await axios.post<IFormGroup>(requestUrl, cleanEntity(formGroup));
    thunkAPI.dispatch(getEntities({id}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const saveMetadataToFormGroup = createAsyncThunk(
  'formGroup/save_metadata',
  async ({formGroup, resetForms}: {formGroup: IFormGroup, resetForms?: boolean}, thunkAPI) => {
    const requestUrl = `${apiUrl}/${formGroup.id}/update`;
    const resetQuery = resetForms ? '?resetForms=true' : '';
    const result = await axios.patch<IFormGroup>(requestUrl+resetQuery,cleanEntity(formGroup));
    if (resetForms) {
      thunkAPI.dispatch(getFormsByOrganisation({ id: formGroup.organisation.id, formGroupId: formGroup.id }));
    }
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateFormGroup = createAsyncThunk(
  'formGroup/update_group',
  async (formGroup: IFormGroup, thunkAPI) => {
    const requestUrl = `${apiUrl}/${formGroup.id}/update`;
    const result = await axios.patch<IFormGroup>(requestUrl,cleanEntity(formGroup));
    thunkAPI.dispatch(getEntities({id: formGroup.organisation.id}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const FormGroupSlice = createEntitySlice({
  name: 'formGroup',
  initialState,
  reducers: {
    /* update the metadata values */
    updateMetadataValues(state, action: PayloadAction<[key: string, value: string | number]>) {
      const [key, value] = action.payload;
      const originalMetadata = JSON.parse(state.entity?.metadata ?? '{}')
      const metadata = {
        ...originalMetadata,
        values: {
          ...originalMetadata.values,
          [key]: value,
        }
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          metadata: JSON.stringify(metadata),
        }
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, createFormGroup, saveMetadataToFormGroup, updateFormGroup), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, createFormGroup, saveMetadataToFormGroup, updateFormGroup), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, resetEntity, updateMetadataValues } = FormGroupSlice.actions;

// Reducer
export default FormGroupSlice.reducer;
