import React from 'react';
import { Container, Nav, Navbar, NavbarToggler } from 'reactstrap';
import './header.scss';
import { AccountMenu, EntitiesMenu } from 'app/shared/layout/menus';
import { Brand } from 'app/shared/layout/navigation/sidebar/SidebarComponents';
import { FarmSwitcher } from './FarmSwitcher';
import { Notifications } from './Notifications';
import { useAppSelector } from 'app/config/store';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarXmark, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { useIsAdmin } from 'app/hooks/useIsAdmin';

interface Props {
  toggle: () => void;
  isAuthenticated: boolean;
  isAdmin: boolean;
}

export const Header = ({ toggle, isAuthenticated, isAdmin }: Props) => {
  const preferences = useAppSelector(state => state.preferences.entity);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const [, isSuperView] = useIsAdmin();

  const getIcon = () => {
    return preferences.weeklyEmail ? faCalendarCheck : faCalendarXmark;
  };
  return (
    <Container fluid className="w-100 bg-white shadow" data-cy="navbar">
      <Navbar light className={'header'}>
        {isAuthenticated ? <NavbarToggler onClick={toggle} /> : <Brand />}
        {!isInProduction && <p className="navbar-version">{VERSION}</p>}
        <Nav id="header-tabs" navbar className="ms-auto d-flex flex-row gap-2 align-items-center">
          {isAuthenticated && <FarmSwitcher />}
          {isAuthenticated && isAdmin && !isSuperView && <EntitiesMenu />}
          {isAuthenticated && !isSuperView && (
            <Link to="/account/settings" data-cy="settings" aria-label="Settings menu to toggle your weekly email reminders">
              <FontAwesomeIcon size="lg" className="nav-link" title="Change your weekly email reminders preferences" icon={getIcon()} />
            </Link>
          )}
          {isAuthenticated && !isSuperView && <Notifications />}

          <AccountMenu isAuthenticated={isAuthenticated} />
        </Nav>
      </Navbar>
    </Container>
  );
};
