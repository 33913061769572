export enum OrganisationType {
  FARM = 'FARM',

  COOP_FARM = 'COOP_FARM',

  VET_PRACTICE = 'VET_PRACTICE',

  CONSULTANCY = 'CONSULTANCY',
  NUTRITION_PRACTICE = 'NUTRITION_PRACTICE',
}
