import React, {ReactChild} from 'react';
import {Card} from "reactstrap";

interface Props {
  imgSrc: string;
  imgAlt: string;
  children: ReactChild | ReactChild[];
  noBack?: true;
}

export const UnauthenticatedPageWrapper = ({imgSrc, imgAlt, children, noBack}: Props) => {
  return (
    <div className='single-page p-4 m-0 w-100 h-100 d-flex gap-4'>
      <main className='col col-xs-100 col md-50 h-100 overflow-auto'>

        <div className='col col-10 p-4 d-flex flex-column gap-3 align-items-center mx-auto'>
          {/* Hide Back Button */}
          {/*  {!noBack && <BackButton className='align-self-start'/>}*/}
          {children}
        </div>
      </main>
      <aside className='w-50 h-100 mh-100 d-none d-md-block overflow-hidden'>
        <Card className={'h-100 mh-100'}>
          <img src={imgSrc}
               alt={imgAlt}
               className='h-100 w-100'
               style={{objectFit: 'cover', maxHeight: '100%'}}
          />
        </Card>
      </aside>
    </div>
  );
}
