import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IHerd, defaultValue } from 'app/shared/model/herd.model';

const initialState: EntityState<IHerd> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

interface FetchParams {
  id?: string | number;
  organisationIds?: (string | number)[];
}

const apiUrl = 'api/herds';

// Actions
export const getEntities = createAsyncThunk(
  'herd/fetch_entity_list',
  async ({ id, organisationIds }: FetchParams) => {
    let requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;

    if (id !== undefined) {
      requestUrl += `&organisationId=${id}`;
    } else if (organisationIds && organisationIds.length > 0) {
      const idsParam = organisationIds.map(individualOrgId => `organisationIds=${individualOrgId}`).join('&');
      requestUrl += `&${idsParam}`;
    }

    return axios.get<IHerd[]>(requestUrl);
  }
);


export const getEntity = createAsyncThunk(
  'herd/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IHerd>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'herd/create_entity',
  async (entity: IHerd, thunkAPI) => {
    const result = await axios.post<IHerd>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'herd/update_entity',
  async (entity: IHerd, thunkAPI) => {
    const result = await axios.put<IHerd>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'herd/partial_update_entity',
  async (entity: IHerd, thunkAPI) => {
    const result = await axios.patch<IHerd>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'herd/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IHerd>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const HerdSlice = createEntitySlice({
  name: 'herd',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = HerdSlice.actions;

// Reducer
export default HerdSlice.reducer;
