import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'app/config/store';
import { AccessLevel } from 'app/shared/model/enumerations/access-level.model';
import { IAccess } from 'app/shared/model/dto/Access.model';
import { userIsConsultant, userIsFarmer, userIsVet } from 'app/shared/util/membership-util';
import { UserRole } from 'app/shared/model/enumerations/user-role.model';
import { useIsAdmin } from './useIsAdmin';
import { MembershipStatus } from 'app/shared/model/enumerations/membership-status.model';


interface FarmAccess {
  IS_ACTIVE: boolean;
  CAN_WRITE: boolean;
  CAN_PERFORM_VET_REVIEW: boolean;
  accessForFarm: IAccess;
  farmId: number | undefined;
  FARM_IS_READ_ONLY: boolean;
}

const defaultFarmAccess: FarmAccess = {
  IS_ACTIVE: false,
  CAN_WRITE: false,
  CAN_PERFORM_VET_REVIEW: false,
  accessForFarm: {},
  farmId: undefined,
  FARM_IS_READ_ONLY: false,
}

interface UserAccess {
  IS_FARMER: boolean;
  IS_VET: boolean;
  IS_CONSULTANT: boolean;
}

const defaultUserAccess: UserAccess = {
  IS_CONSULTANT: false,
  IS_FARMER: false,
  IS_VET: false,
}


export const useFarmAccess = () => {

  const accessList = useAppSelector(state => state.access.entities);
  const farm = useAppSelector(state => state.farm.entity);
  const appUser = useAppSelector(state => state.appUser.entity);

  const [isAdmin] = useIsAdmin();
  const { farmId } = useAppSelector(state => state.appConfig);

  const [access, setAccess] = useState<FarmAccess>(defaultFarmAccess);
  const [userIs, setUserIs] = useState<UserAccess>(defaultUserAccess);

  useEffect(() => {
    const IS_FARMER = isAdmin || userIsFarmer(appUser);
    const IS_VET = userIsVet(appUser);
    const IS_CONSULTANT = userIsConsultant(appUser);
    setUserIs({
      IS_FARMER,
      IS_VET,
      IS_CONSULTANT,
    });

  }, [appUser, isAdmin]);

  useEffect(() => {
    if (isAdmin) {
      setAccess({
        IS_ACTIVE: true,
        CAN_WRITE: true,
        CAN_PERFORM_VET_REVIEW: false,
        farmId,
        FARM_IS_READ_ONLY: false,
        accessForFarm: {
          accessLevel: AccessLevel.READ_WRITE,
          isDirectMember: true,
          isMembershipActive: true,
          membershipStatus: MembershipStatus.CONFIRMED,
          isFarm: true,
          orgActive: true,
          orgId: farmId,
          organisationName: farm?.nameOfOrganisation ?? 'Admin Super View',
        }
      });
      return;
    }
    const accessForFarm = accessList.find(a => a.isFarm && a.orgId === farm.id);
    if (!accessForFarm) {
      setAccess(defaultFarmAccess);
      return;
    }

    const { accessLevel, isMembershipActive, readOnlyOrganisation } = accessForFarm;

    const CAN_WRITE = isMembershipActive && (accessLevel === AccessLevel.MANAGER || accessLevel === AccessLevel.READ_WRITE);
    const CAN_PERFORM_VET_REVIEW = accessForFarm.childOrganisationRole === UserRole.VET;
    setAccess({
      IS_ACTIVE: isMembershipActive,
      CAN_WRITE,
      CAN_PERFORM_VET_REVIEW,
      accessForFarm,
      farmId: accessForFarm.orgId,
      FARM_IS_READ_ONLY: readOnlyOrganisation,
    });

  }, [farm, accessList, isAdmin, farmId]);

  return Object.freeze({ ...access, ...userIs });
}
