import React from 'react';
import {Button} from 'reactstrap';

interface Props {
  buttonText?: string;
  color?: string;
  onClick?: () => void;
  dataCy?: string;
  padRight?: boolean;
}

export const CancelButton = ({buttonText = 'Cancel', color='light', onClick, dataCy='cancel', padRight, }: Props) => {


return (
  <>
    <Button
      data-cy={dataCy}
      onClick={onClick}
      color={color}
    >
      {buttonText}
    </Button>
    {padRight && <div className='flex-grow-1' />}
  </>)
}

