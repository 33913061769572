import React, { useState } from 'react';
import DateInput from './DateInput';

import { InputProps, Input } from 'reactstrap';
import { Controller } from 'react-hook-form';

const InputWrapper = (props: InputProps) => {
  const { type } = props;
  if (type === 'date' || type === 'datetime' || type === 'datetime-local') {
    return <DateInput {...props} />;
  } else {
    return <Input {...props} />;
  }
};

export default InputWrapper;
