import React, { useLayoutEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import {UnauthenticatedPageWrapper} from "app/modules/account/UnauthenticatedPageWrapper";
import { Link } from 'react-router-dom';

export const Logout = () => {
  const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(logout());
    if (logoutUrl) {
      window.location.href = logoutUrl;
    }
  });

  return (
    <UnauthenticatedPageWrapper imgSrc='/content/images/hero/grazing-cow.jpg' imgAlt='A single grazing cow in a field'>
      <h2>Logged out successfully!</h2>
      <Link to='/login' className='btn btn-primary'>
        Sign In
      </Link>
    </UnauthenticatedPageWrapper>
  );
};

export default Logout;
