import dayjs from 'dayjs';

import { APP_DATE_ONLY_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';

export const convertFromServer = (date, format) => (date ? dayjs(date).format(format) : null);

export const convertDateTimeFromServer = date => convertFromServer(date, APP_LOCAL_DATETIME_FORMAT);

export const convertDateFromServer = date => convertFromServer(date, APP_DATE_ONLY_FORMAT);

export const convertDateTimeToServer = date => (date ? dayjs(date).toDate() : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const displayTimeDiffFromNow = date => dayjs(date).fromNow();

export const getTimeNow = () => dayjs().toISOString();

export const getShortMonthNameAndYear = date => {
  return dayjs(date).format('MMM YYYY').toUpperCase();
};

export const oneYearLater = () => {
  return dayjs().add(1, 'year').format('YYYY-MM-DD');
};

export const convertDateToISOString = date => dayjs(date).toISOString();

export const convertDateToDateCurrentTimeISOString = date => {
  const currentTime = dayjs();
  const combinedDateTime = dayjs(date)
    .set('hour', currentTime.hour())
    .set('minute', currentTime.minute())
    .set('second', currentTime.second());

  return combinedDateTime.toISOString();
};
