import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPreferences, defaultValue } from 'app/shared/model/preferences.model';

const initialState: EntityState<IPreferences> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/preferences';

// Actions

export const getEntity = createAsyncThunk(
  'preferences/fetch_entity',
  async () => {
    const requestUrl = `${apiUrl}`;
    return axios.get<IPreferences>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'preferences/update_entity',
  async (entity: IPreferences, thunkAPI) => {
    const result = await axios.put<IPreferences>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntity());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'preferences/partial_update_entity',
  async (entity: IPreferences, thunkAPI) => {
    const result = await axios.patch<IPreferences>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntity());
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PreferencesSlice = createEntitySlice({
  name: 'preferences',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending( getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(updateEntity, partialUpdateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = PreferencesSlice.actions;

// Reducer
export default PreferencesSlice.reducer;
