import dayjs from 'dayjs';
import { IVetPractice } from 'app/shared/model/vet-practice.model';
import { IFarmDetails } from 'app/shared/model/farm-details.model';
import { ContractStatus } from 'app/shared/model/enumerations/contract-status.model';

export interface IMembershipContract {
  id?: number;
  startDate?: string;
  endDate?: string;
  coolingOffPeriod?: string;
  paymentsRemaining?: number;
  status?: ContractStatus;
  autoRenew?: boolean;
  singlePayment?: boolean;
  vetPractice?: IVetPractice | null;
  farm?: IFarmDetails | null;
}

export const defaultValue: Readonly<IMembershipContract> = {
  autoRenew: false,
  singlePayment: false,
};


/**
 * The contracts array in redux is ordered by start date, where the latest start date is at the start of the array. However, if multiple contracts start on the same day, the order can not be guaranteed.
 * 
 * This would come about because a contract is CANCELLED on the same day that a new PENDING one starts.
 * 
 * This method selects the most recent contract, on the basis that a CANCELLED contract has the end date set to the date it was cancelled.
 * 
 * @param contracts the contracts to search through
 * @returns the most recent contract, or undefined
 */
export const getMostRecentContract = (contracts: ReadonlyArray<IMembershipContract>): IMembershipContract | undefined => {
  if (contracts.length === 0) return undefined;
  const earliestStartDate = contracts[0].startDate;
  const sameDateContracts = contracts.filter(c => c.startDate === earliestStartDate);
  return sameDateContracts.reduce((latest, current) => {
    return dayjs(latest.endDate).isBefore(current.endDate) ? current : latest;
  }, contracts[0]);
}
