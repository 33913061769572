import { IMembershipContract } from 'app/shared/model/membership-contract.model';
import { IStripeDetails } from 'app/shared/model/stripe-details.model';

export interface IVetPractice {
  id?: number;
  name?: string;
  address1?: string;
  address2?: string | null;
  area?: string | null;
  town?: string;
  county?: string;
  postcode?: string;
  tel?: string;
  email?: string;
  hidden?: boolean | null;
  membershipContracts?: IMembershipContract[] | null;
  stripeDetails?: IStripeDetails | null;
}

export const defaultValue: Readonly<IVetPractice> = {
  hidden: false,
};
