export interface CalendarFilter {
  type: FilterType;
  text: string;
  id: number | string;
}

export enum FilterType {
  FARM = 'FARM',
  USER = 'USER',
  ORG = 'ORG',
  FORM = 'FORM',
  HERD = 'HERD',
}

export const getFilterId = (item: CalendarFilter) => `${item.id}-${item.type}`;
