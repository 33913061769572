import {IAppUser} from "../model/app-user.model";
import {UserRole, isConsultantRole, isFarmerRole, isVetRole} from "../model/enumerations/user-role.model";

export type MemberType = 'Farmer' | 'Vet' | 'Consultant' | 'Client' | 'VetColleague' | 'ConsultantColleague' | 'Any' | 'None';

export const getRolesByMemberType = (memberType: MemberType): UserRole[] => {
  switch (memberType) {
    case 'Farmer':
      return [UserRole.FARMER, UserRole.FARM_STAFF];
    case 'Vet':
      return [UserRole.VET];
    case 'Consultant':
      return [UserRole.CONSULTANT, UserRole.NUTRITIONIST];
    case 'Any':
    case 'Client':
    case 'None':
    default:
      return [
        UserRole.FARMER, UserRole.FARM_STAFF, UserRole.VET, UserRole.CONSULTANT, UserRole.NUTRITIONIST
      ];
  }
}

export const getMemberTypeByRole = (userRole: UserRole): MemberType =>{
  switch (userRole) {
    case UserRole.FARMER:
    case UserRole.FARM_STAFF:
      return 'Farmer'
    case UserRole.VET:
      return 'Vet'
    case UserRole.VET_ADMIN:
      return 'VetColleague'
    case UserRole.CONSULTANT:
    case UserRole.NUTRITIONIST:
      return 'Consultant'
    default:
      return 'Any'
  }

}

export const userIsVet = (appUser: IAppUser) => isVetRole(appUser.primaryRole);

export const userIsFarmer = (appUser: IAppUser) => isFarmerRole(appUser.primaryRole);

export const userIsConsultant = (appUser: IAppUser) => isConsultantRole(appUser.primaryRole);
