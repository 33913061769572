import axios from 'axios';
import { AnyAction, createAsyncThunk, isFulfilled, isPending, Reducer } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IMembership } from 'app/shared/model/membership.model';
import { IInvitation } from 'app/shared/model/dto/Invitation.model';
import { getInvites } from 'app/shared/reducers/invite.reducer';

export interface MembershipState extends EntityState<IMembership> {
  childEntities: ReadonlyArray<IMembership>;
}

const initialState: MembershipState = {
  loading: false,
  errorMessage: null,
  entities: [],  // user -> org memberships
  childEntities: [],  // org -> org memberships
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/memberships';

// Actions

export const getEntities = createAsyncThunk('membership/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IMembership[]>(requestUrl);
});

export const getEntitiesByOrganisation = createAsyncThunk(
  'membership/fetch_entity_list_by_organisation',
  async ({id, includeInvites, forChildOrg}: {id: string | number, includeInvites?: boolean, forChildOrg?: boolean}, thunkAPI) => {
    const requestUrl = `${apiUrl}/organisation/${id}?cacheBuster=${new Date().getTime()}${forChildOrg ? '&childOrg=true' : ''}${includeInvites ? '&includeInvites=true' : ''}`;
    const result = await axios.get<IMembership[]>(requestUrl);
    thunkAPI.dispatch(getInvites());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getEntityByOrganisation = createAsyncThunk(
  'membership/fetch_entity_by_organisation',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/single/organisation/${id}`;
    return axios.get<IMembership>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createInvitation = createAsyncThunk(
  'membership/create_invitation',
  async (entity: IInvitation) => {
    const result = await axios.post<IMembership>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const sendInvitation = createAsyncThunk(
  'invitations',
  async (entity: IInvitation) => {
    const requestUrl = `${apiUrl}/invitation/`
    const result = await axios.post<IMembership>(requestUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'membership/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IMembership>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'membership/create_entity',
  async (entity: IMembership, thunkAPI) => {
    const result = await axios.post<IMembership>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'membership/update_entity',
  async (entity: IMembership, thunkAPI) => {
    const result = await axios.put<IMembership>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'membership/partial_update_entity',
  async (entity: IMembership) => {
    const result = await axios.patch<IMembership>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'membership/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IMembership>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const acceptMembership = createAsyncThunk(
  'membership/accept_entity',
  async (entity: IMembership, thunkAPI) => {
    const requestUrl = `${apiUrl}/${entity.id}/accept`;
    const result = await axios.patch<IMembership>(requestUrl, cleanEntity(entity));
    thunkAPI.dispatch(getInvites());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const rejectMembership = createAsyncThunk(
  'membership/reject_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}/reject`;
    const result = await axios.delete<IMembership>(requestUrl);
    thunkAPI.dispatch(getInvites());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const transferManagerAccess = createAsyncThunk(
  'membership/transfer_manager_access',
  async ({ idFrom, idTo, membership }: { idFrom: string | number, idTo: string | number, membership: IMembership }) => {
    const requestUrl = `${apiUrl}/${idFrom}/transfer/${idTo}`;
    const result = await axios.patch<IMembership>(requestUrl, cleanEntity(membership));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const MembershipSlice = createEntitySlice({
  name: 'membership',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntity, getEntityByOrganisation), (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity, rejectMembership), state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesByOrganisation), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data.filter(e => e.user !== null),
          childEntities: data.filter(e => e.childOrganisation !== null),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, createInvitation, acceptMembership, transferManagerAccess), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntitiesByOrganisation, getEntityByOrganisation), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, createInvitation, acceptMembership, rejectMembership, transferManagerAccess), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = MembershipSlice.actions;

// Reducer
export default MembershipSlice.reducer as Reducer<MembershipState, AnyAction>;
