import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICustomTemplate, defaultValue } from 'app/shared/model/custom-template.model';

const initialState: EntityState<ICustomTemplate> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/custom-templates';

// Actions

export const getEntities = createAsyncThunk('customTemplate/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<ICustomTemplate[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'customTemplate/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICustomTemplate>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'customTemplate/create_entity',
  async (entity: ICustomTemplate, thunkAPI) => {
    const result = await axios.post<ICustomTemplate>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'customTemplate/update_entity',
  async (entity: ICustomTemplate, thunkAPI) => {
    const result = await axios.put<ICustomTemplate>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'customTemplate/partial_update_entity',
  async (entity: ICustomTemplate, thunkAPI) => {
    const result = await axios.patch<ICustomTemplate>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getCustomTemplates({id: entity.organisation.id}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'customTemplate/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICustomTemplate>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getCustomTemplates = createAsyncThunk(
  'customTemplate/get_for_organisation',
  async ({id}: {id: string | number}) => {
    return axios.get<ICustomTemplate[]>(`${apiUrl}/organisation/${id}`);
  }
);

export const generateNewCustomTemplate = createAsyncThunk(
  'customTemplate/generate_new',
  async (entity: ICustomTemplate, thunkAPI) => {
    const requestUrl = `${apiUrl}/generate`;
    const result = await axios.post<ICustomTemplate>(requestUrl, cleanEntity(entity));
    thunkAPI.dispatch(getCustomTemplates({id: entity.organisation.id}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getAvailableCustomTemplates = createAsyncThunk(
  'customTemplate/get_available',
  async ({farmId, category}: {farmId: string | number, category?: string}) => {
    return axios.get<ICustomTemplate[]>(`${apiUrl}/available`, { params: { farmId, category } });
  },
  { serializeError: serializeAxiosError }
);

export const publishCustomTemplate = createAsyncThunk(
  'customTemplate/publish_unpublish',
  async ({entity}: {entity: ICustomTemplate}, thunkAPI) => {
    const requestUrl = `${apiUrl}/${entity.id}/publish`;
    const result = await axios.patch<ICustomTemplate>(requestUrl, cleanEntity(entity));
    thunkAPI.dispatch(getCustomTemplates({id: entity.organisation.id}));
    return result;
  },
  { serializeError: serializeAxiosError }
)
// slice

export const CustomTemplateSlice = createEntitySlice({
  name: 'customTemplate',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getCustomTemplates, getAvailableCustomTemplates), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, generateNewCustomTemplate, publishCustomTemplate), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getCustomTemplates, getAvailableCustomTemplates), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, generateNewCustomTemplate, publishCustomTemplate), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CustomTemplateSlice.actions;

// Reducer
export default CustomTemplateSlice.reducer;
