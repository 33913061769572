export enum MembershipStatus {
  /**
   * Signifies a `IMembership` between an `organisation` and a `childOrganisation` that is incomplete.
   * Typically this is because the `childOrganisation` does not yet exist, so the membership is temporarily linked to a user.
   */
  PENDING = 'PENDING',
  /**
   * Signifies a `IMembership` where an organisation has invited a user.
   */
  INVITED = 'INVITED',
  /**
   * Signifies a `IMembership` where a user has requested to join an organisation.
   */
  REQUESTED = 'REQUESTED',
  /**
   * Signifies a `IMembership` that has been agreed by both parties.
   */
  CONFIRMED = 'CONFIRMED',
}
