import { Feedback } from 'app/shared/feedback/Feedback';
import './footer.scss';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { useAppSelector } from 'app/config/store';

const Footer = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer page-content">
      <Row>
        <Col md="12" className="align-content-center">
          <p className="m-0">© SRUC {currentYear} All Rights Reserved.</p>
        </Col>
      </Row>
      {isAuthenticated && <Feedback linkText="Send feedback to HerdPlan" defaultSubject="General Feedback" />}
    </div>
  );
};

export default Footer;
