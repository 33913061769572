import dayjs from 'dayjs';
import { ITemplate } from 'app/shared/model/template.model';
import { IFormData } from 'app/shared/model/form-data.model';
import { IApproval } from 'app/shared/model/approval.model';
import { IDocument } from 'app/shared/model/document.model';
import { IAction } from 'app/shared/model/action.model';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { IFormGroup } from 'app/shared/model/form-group.model';
import { IHerd } from 'app/shared/model/herd.model';
import { FormState } from 'app/shared/model/enumerations/form-state.model';
import { FormType } from 'app/shared/model/enumerations/form-type.model';

export interface IForm {
  id?: number;
  state?: FormState;
  dateIssued?: string | null;
  dateCompleted?: string | null;
  dateReviewed?: string | null;
  dateApproved?: string | null;
  dateObsolete?: string | null;
  lastUpdated?: string | null;
  formType?: FormType;
  title?: string | null;
  template?: ITemplate;
  formData?: IFormData[] | null;
  approvals?: IApproval[] | null;
  documents?: IDocument[] | null;
  actions?: IAction[] | null;
  organisation?: IOrganisation;
  formGroup?: IFormGroup | null;
  herds?: IHerd[] | null;
}

export const defaultValue: Readonly<IForm> = {};
