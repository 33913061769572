import React from 'react';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/entities/audit-event">
        Audit Event
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/organisation">
        Organisation
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/app-user">
        App User
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/farm-details">
        Farm Details
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/membership">
        Membership
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/event">
        Event
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/vet-practice">
        Vet Practice
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/herd">
        Herd
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/stock">
        Stock
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/form">
        Form
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/action">
        Action
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/template">
        Template
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/json-schema">
        Json Schema
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/workflow-step">
        Workflow Step
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/form-data">
        Form Data
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/approval">
        Approval
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/document">
        Document
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/default-actions">
        Default Actions
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/form-group">
        Form Group
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/consultancy">
        Consultancy
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/competency">
        Competency
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/target">
        Target
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/custom-template">
        Custom Template
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/routine-treatment">
        Routine Treatment
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/membership-contract">
        Membership Contract
      </MenuItem>
      <MenuItem icon="asterisk" to="/entities/stripe-details">
        Stripe Details
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu as React.ComponentType<any>;
