import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IOrganisation, defaultValue } from 'app/shared/model/organisation.model';

const initialState: EntityState<IOrganisation> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/organisations';

// Actions

export const getEntities = createAsyncThunk('farm/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}&farm=true`;
  return axios.get<IOrganisation[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'farm/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IOrganisation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'farm/create_entity',
  async (entity: IOrganisation, thunkAPI) => {
    const result = await axios.post<IOrganisation>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'farm/update_entity',
  async (entity: IOrganisation, thunkAPI) => {
    const result = await axios.put<IOrganisation>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'farm/partial_update_entity',
  async (entity: IOrganisation, thunkAPI) => {
    const result = await axios.patch<IOrganisation>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'farm/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IOrganisation>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const FarmSlice = createEntitySlice({
  name: 'farm',
  initialState,
  extraReducers(builder) {
    builder
    .addCase(getEntity.fulfilled, (state, action) => {
      state.loading = false;
      state.entity = action.payload.data;
    })
    .addCase(deleteEntity.fulfilled, state => {
      state.updating = false;
      state.updateSuccess = true;
      state.entity = {};
    })
    .addMatcher(isFulfilled(getEntities), (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        loading: false,
        entities: data,
      };
    })
    .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
      state.updating = false;
      state.loading = false;
      state.updateSuccess = true;
      state.entity = action.payload.data;
    })
    .addMatcher(isPending(getEntities, getEntity), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.loading = true;
    })
    .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.updating = true;
    });
  },
});

export const { reset, resetEntity } = FarmSlice.actions;

// Reducer
export default FarmSlice.reducer;
