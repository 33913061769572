import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, serializeAxiosError, createPaginatedEntitySlice, PaginatedEntityState } from 'app/shared/reducers/reducer.utils';
import {IEvent, defaultValue} from 'app/shared/model/event.model';
import {EventType} from 'app/shared/model/enumerations/event-type.model';



export const initialState: PaginatedEntityState<IEvent> = {
  loading: false,
  errorMessage: null,
  entities: {},
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalUnread: 0,
  currentPage: 1,
  pageSize: 5,
  pages: {}
}

const apiUrl = 'api/events';

// Actions

export const getPage = createAsyncThunk(
  'notifications/fetch_page',
  async ({page, size, sort}: IQueryParams) => {
    const requestUrl = `${apiUrl}?type=${EventType.NOTIFICATION}${sort? `&page=${page}&size=${size}&sort=${sort}` : ''}`;
    return axios.get<IEvent[]>(requestUrl);
  }
);

export const getNotificationUnreadCount = createAsyncThunk(
  'notifications/fetch_unread_count',
  async () => {
    const requestUrl = `${apiUrl}/unread-notification-count`;
    return axios.get<IEvent>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'notifications/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEvent>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk(
  'notifications/fetch_all_entities',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?cachBuster=${new Date().getTime()}`;
    return axios.get<IEvent[]>(requestUrl);
  }
);

export const createEntity = createAsyncThunk(
  'notifications/create_entity',
  async (entity: IEvent, thunkAPI) => {
    const result = await axios.post<IEvent>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getPage({page: 0, size: 5}))
    return result;
  }
);

export const partialUpdateEntity = createAsyncThunk(
  'notifications/partial_update_entity',
  async ({entity, showAlert}: {entity: IEvent, showAlert: boolean}, thunkAPI) => {
    const result = await axios.patch<IEvent>(`${apiUrl}/${entity.id}?toast=${showAlert}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntity(entity.id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const markAllRead = createAsyncThunk(
  'notifications/mark_all_read',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/mark_all_read/${id}`;
    return await axios.patch<IEvent[]>(`${requestUrl}`);
  },
  { serializeError: serializeAxiosError }
)

export const markRead = createAsyncThunk(
  'notifications/mark_read',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/mark_read/${id}`;
    return await axios.patch<IEvent[]>(`${requestUrl}`);
  },
  { serializeError: serializeAxiosError }
)

// slice

export const NotificationSlice = createPaginatedEntitySlice({
  name: 'notifications',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
        state.totalUnread = parseInt(action.payload.headers['x-total-unread-notifications'], 10);
      })
      .addCase(getNotificationUnreadCount.fulfilled, (state, {payload}) => {
        const unreadCount = parseInt(payload.headers['x-total-unread-notifications'], 10);
        return {
          ...state,
          totalUnread: unreadCount,
        }
      })
      .addCase(getPage.fulfilled, (state, {payload, meta}) => {
        const {page:currentPage, size: pageSize} = meta.arg;
        return {
          ...state,
          loading: false,
          currentPage,
          pageSize,
          entities: {
            ...state.entities,
            ...(mapEntities(payload.data))
          },
          pages: {
            ...state.pages,
            [currentPage]: payload.data.map(e => e.id),
          },
          totalUnread: parseInt(payload.headers['x-total-unread-notifications'], 10),
          totalItems: parseInt(payload.headers['x-total-count'], 10),
        }
      })
      .addMatcher(isPending(getPage, getEntities, getEntity, getNotificationUnreadCount), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isFulfilled(markAllRead, markRead), (state, action) => {
        const mapEntities = (entities) => {
          if (Array.isArray(entities)) {
            return Object.fromEntries(entities.map((entity) => [entity.id, entity]));
          } else {
            return { [entities.id]: entities };
          }
        };
        return {
          ...state,
          updating: false,
          updateSuccess: true,
          entities: {
            ...state.entities,
            ...(mapEntities(action.payload.data))
          },
          totalUnread: parseInt(action.payload.headers['x-total-unread-notifications'], 10)
        }
      })
      .addMatcher(isFulfilled(createEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entities[action.payload.data.id] = action.payload.data;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(createEntity, partialUpdateEntity, markRead, markAllRead), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          updating: false,
          loading: false,
          updateSuccess: false,
          entities: {
            ...state.entities,
            ...(mapEntities(action.payload.data))
          },
          totalUnread: parseInt(action.payload.headers['x-total-unread-notifications'], 10)
        }
      })
  },
});

export const { reset } = NotificationSlice.actions;

// Reducer
export default NotificationSlice.reducer;


// util
const mapEntities = (entities: IEvent[]) => {
  return Object.fromEntries(entities.map((entity): [number, IEvent] => [entity.id, entity]));
}
