import dayjs from 'dayjs';
import { IAppUser } from 'app/shared/model/app-user.model';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { UserRole } from 'app/shared/model/enumerations/user-role.model';
import { AccessLevel } from 'app/shared/model/enumerations/access-level.model';
import { MembershipStatus } from 'app/shared/model/enumerations/membership-status.model';
import { OrganisationType } from './enumerations/organisation-type.model';

export interface IMembership {
  id?: number;
  active?: boolean;
  dateCreated?: string;
  role?: UserRole;
  access?: AccessLevel;
  status?: MembershipStatus;
  user?: IAppUser | null;
  organisation?: IOrganisation;
  childOrganisation?: IOrganisation | null;
}

export const defaultValue: Readonly<IMembership> = {
  active: false,
};

export const betweenFarmAndVetPractice = (membership: IMembership) => {
  const orgType = membership?.organisation?.organisationType;
  const childOrgType = membership?.childOrganisation?.organisationType;

  return (orgType === OrganisationType.FARM || orgType === OrganisationType.COOP_FARM) && childOrgType === OrganisationType.VET_PRACTICE;
}
