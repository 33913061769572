import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, replaceInEntities } from 'app/shared/reducers/reducer.utils';
import { IMembershipContract, defaultValue } from 'app/shared/model/membership-contract.model';
import { ContractStatus } from 'app/shared/model/enumerations/contract-status.model';

const initialState: EntityState<IMembershipContract> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/membership-contracts';

// Actions

export const getEntities = createAsyncThunk('membershipContract/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IMembershipContract[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'membershipContract/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IMembershipContract>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'membershipContract/create_entity',
  async (entity: IMembershipContract, thunkAPI) => {
    const result = await axios.post<IMembershipContract>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'membershipContract/update_entity',
  async (entity: IMembershipContract, thunkAPI) => {
    const result = await axios.put<IMembershipContract>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'membershipContract/partial_update_entity',
  async (entity: IMembershipContract, thunkAPI) => {
    const result = await axios.patch<IMembershipContract>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'membershipContract/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IMembershipContract>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// user actions

export const getAllForVetPractice = createAsyncThunk(
  'membershipContract/getAllForVetPractice',
  async ({ vetPracticeDetailsId, filter }: { vetPracticeDetailsId: string | number, filter?: ContractStatus[] }) => {
    const requestUrl = `${apiUrl}/vet-practice/${vetPracticeDetailsId}`;
    return await axios.get<IMembershipContract[]>(requestUrl, {
      params: {
        cacheBuster: new Date().getTime(),
        statuses: filter?.join(',') || undefined,
      },
    });
  },
  { serializeError: serializeAxiosError }
);

export const getAllForFarm = createAsyncThunk(
  'membershipContract/getAllForFarm',
  async ({ farmDetailsId, filter }: { farmDetailsId: string | number, filter?: ContractStatus[] }) => {
    const requestUrl = `${apiUrl}/farm/${farmDetailsId}`;
    return await axios.get<IMembershipContract[]>(requestUrl, {
      params: {
        cacheBuster: new Date().getTime(),
        statuses: filter?.join(',') || undefined,
      },
    });
  },
  { serializeError: serializeAxiosError }
);

export const toggleAutoRenew = createAsyncThunk(
  'membershipContract/toggleAutoRenew',
  async ({ entity }: { entity: IMembershipContract }) => {
    const requestUrl = `${apiUrl}/toggle-auto-renew`;
    return axios.patch<IMembershipContract>(requestUrl, entity);
  },
  { serializeError: serializeAxiosError }
);

export const toggleSinglePayment = createAsyncThunk(
  'membershipContract/toggleSinglePayment',
  async ({ entity }: { entity: IMembershipContract }) => {
    const requestUrl = `${apiUrl}/toggle-single-payment`;
    return axios.patch<IMembershipContract>(requestUrl, entity);
  },
  { serializeError: serializeAxiosError }
);

export const agreeContract = createAsyncThunk(
  'membershipContract/agreeContract',
  async ({ entity }: { entity: IMembershipContract }) => {
    const requestUrl = `${apiUrl}/agree-contract`;
    return axios.patch<IMembershipContract>(requestUrl, entity);
  },
  { serializeError: serializeAxiosError }
);

export type ExitContractMode = 'cancel' | 'terminate';

export const endContract = createAsyncThunk(
  'membershipContract/endContract',
  async({ entity, mode }: {entity: IMembershipContract, mode: ExitContractMode}) => {
    const requestUrl = `${apiUrl}/end-contract`;
    return axios.patch<IMembershipContract>(requestUrl, entity, { params: { mode }});
  },
  { serializeError: serializeAxiosError }
);


// slice

export const MembershipContractSlice = createEntitySlice({
  name: 'membershipContract',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getAllForVetPractice, getAllForFarm), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(toggleAutoRenew, toggleSinglePayment, agreeContract, endContract), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
        state.entities = replaceInEntities(action.payload.data, state.entities);
      })
      .addMatcher(isPending(getEntities, getEntity, getAllForVetPractice, getAllForFarm), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, toggleAutoRenew, toggleSinglePayment, agreeContract, endContract), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = MembershipContractSlice.actions;

// Reducer
export default MembershipContractSlice.reducer;
