import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IMembership, defaultValue } from '../model/membership.model';

const initialState: EntityState<IMembership> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/invite';

// Actions
export const getInvites = createAsyncThunk(
  'invite/get_invites',
  async () => {
    return axios.get<IMembership[]>(apiUrl);
  },
  { serializeError: serializeAxiosError }
);

// Slice
export const Invite = createEntitySlice({
  name: 'farmUsers',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getInvites), (state, { payload: { data } }) => {
        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isPending(getInvites), (state, action) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = Invite.actions;

// Reducer
export default Invite.reducer;
