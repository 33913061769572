export enum EventType {
  MEETING = 'MEETING',

  EVENT = 'EVENT',

  ACTION = 'ACTION',

  TASK = 'TASK',

  NOTIFICATION = 'NOTIFICATION',
}
