import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createPaginatedEntitySlice, PaginatedEntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUpcomingItem, defaultValue } from '../model/dto/UpcomingItem.model';

const initialState: PaginatedEntityState<IUpcomingItem> = {
  loading: false,
  errorMessage: null,
  entities: {},
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalUnread: 0,
  currentPage: 1,
  pageSize: 5,
  pages: {},
  headers: { hasNext: false },
}

// Actions
export const getUpcomingItems = createAsyncThunk(
  'upcomingItem/fetch_list',
  async ({ farmId, showAll, page, pageSize, showOverdue }: { farmId: number, showAll: boolean, page: number, pageSize: number, showOverdue: boolean }) => {
    const requestUrl = `api/upcoming-items/farm/${farmId}`;
    return axios.get<IUpcomingItem[]>(requestUrl, { params: { showAll, page, pageSize, showOverdue }});
  },
  { serializeError: serializeAxiosError }
);

// Slice
export const UpcomingItemSlice = createPaginatedEntitySlice({
  name: 'upcomingItem',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getUpcomingItems), (state, {payload, meta}) => {
        const { data, headers } = payload;
        const { page, pageSize } = meta.arg;
        return {
          ...state,
          loading: false,
          entities: {
            ...(page === 0 ? {} : state.entities),
            ...(mapEntities(data, page, pageSize)),
          },
          pages: {
            ...state.pages,

          },
          headers: {
            hasNext: headers['x-items-has-next'] === 'true',
          }
        };
      })
      .addMatcher(isPending(getUpcomingItems), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = UpcomingItemSlice.actions;

// Reducer
export default UpcomingItemSlice.reducer;


const mapEntities = (data: IUpcomingItem[], page: number, pageSize: number) => {
  return Object.fromEntries(data.map((entity, index): [number, IUpcomingItem] => [(page * pageSize) + index, entity]));
}