import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IForm, defaultValue } from 'app/shared/model/form.model';
import { IFormApprovalDto } from 'app/shared/model/dto/FormApproval.model';
import { FormType } from 'app/shared/model/enumerations/form-type.model';

const initialState: EntityState<IForm> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

type FormArgsCategory = 'INF_DISEASE__' | 'PAFP__' | 'PRODUCTION_PLAN' | 'TEAM_CATCH_UP' | 'FARM_POLICIES' | '';
interface GetFormsArgs {
  id: string | number;
  category?: FormArgsCategory;
  excludeCategory?: FormArgsCategory;
  showObsolete?: boolean;
  formGroupId?: number | string;
}

const getCategory = (formType: FormType): FormArgsCategory  => {
  if (formType.startsWith('INF_DISEASE__')) return 'INF_DISEASE__';
  if (formType.startsWith('PAFP__')) return 'PAFP__';
  if (formType.startsWith('PRODUCTION_PLAN')) return 'PRODUCTION_PLAN';
  if (formType.startsWith('FARM_POLICIES')) return 'FARM_POLICIES';
  if (formType.startsWith('TEAM_CATCH_UP')) return 'TEAM_CATCH_UP';
  return '';
}

const apiUrl = 'api/forms';

// Actions

export const getToReview = createAsyncThunk(
  'form/fetch_to_review',
  async ({id, range}: {id: string | number, range?: number}) => {
    const requestUrl = `${apiUrl}/to-review?farmId=${id}${range ? `&range=${range}` : ''}`;
    return axios.get<IForm[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk('form/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IForm[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'form/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IForm>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getFormsByOrganisation = createAsyncThunk(
  'form/fetch_all_by_organisation',
  async (args: GetFormsArgs) => {
    const requestUrl = `${apiUrl}/organisation/${args.id}`;
    return axios.get<IForm[]>(requestUrl, { params: {
      cacheBuster: new Date().getTime(),
      category: args.category,
      showObsolete: args.showObsolete,
      formGroupId: args.formGroupId,
      excludeCategory: args.excludeCategory,
    }});
  },
  { serializeError: serializeAxiosError }
);

export const getEntityWithNested = createAsyncThunk(
  'form/fetch_entity_nested',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/include-nested/${id}`;
    return axios.get<IForm>(requestUrl);
  },
  { serializeError: serializeAxiosError }
)

export const getKpisForm = createAsyncThunk(
  'form/fetch_kpis_form',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/farm/${id}/kpis`;
    return axios.get<IForm>(requestUrl);
  }
);

export const issueForm = createAsyncThunk(
  'form/issue_entity',
  async ({entity, formGroupId, customTemplateId}: {entity: IForm, formGroupId?: number, customTemplateId?: string | number}, thunkAPI) => {
    const requestUrl = `${apiUrl}/issue`;
    const result = await axios.post<IForm>(requestUrl, cleanEntity(entity), { params: { formGroupId, customTemplateId }});
    thunkAPI.dispatch(getFormsByOrganisation({id: entity.organisation.id, category: getCategory(entity.formType)}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const saveForm = createAsyncThunk(
  'form/save_entity',
  async ({entity, saveType}: {entity: IForm, saveType: 'save' | 'complete' | 'review'}) => {
    const requestUrl = `${apiUrl}/${saveType}/${entity.id}`;
    return await axios.put<IForm>(requestUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const approveForm = createAsyncThunk(
  'form/approve',
  async (entity: IFormApprovalDto) => {
    const requestUrl = `${apiUrl}/approve/${entity.formId}`;
    return await axios.post<IForm>(requestUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const obsoleteForm = createAsyncThunk(
  'form/make_obsolete',
  async (entity: IForm, thunkAPI) => {
    const requestUrl = `${apiUrl}/obsolete/${entity.id}`;
    const result = await axios.post<IForm>(requestUrl);
    thunkAPI.dispatch(getFormsByOrganisation({id: entity.organisation.id, category: getCategory(entity.formType)}));
    return result;
  }
);

export const createEntity = createAsyncThunk(
  'form/create_entity',
  async (entity: IForm, thunkAPI) => {
    const result = await axios.post<IForm>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'form/update_entity',
  async (entity: IForm, thunkAPI) => {
    const result = await axios.put<IForm>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'form/partial_update_entity',
  async (entity: IForm, thunkAPI) => {
    const result = await axios.patch<IForm>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'form/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IForm>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getFormLibraryCategories = createAsyncThunk(
  'form/library_categories',
  async (args: { id: number | string, category: string}) => {
    const requestUrl = `${apiUrl}/library-categories/${args.id}`;
    const params = `?type=${args.category}`;
    return axios.get<IForm[]>(`${requestUrl}${params}`);
  }
);

// slice

export const FormSlice = createEntitySlice({
  name: 'form',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntity, getEntityWithNested, getKpisForm), (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities, getFormsByOrganisation, getToReview, getFormLibraryCategories), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, saveForm, issueForm, approveForm, obsoleteForm), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntityWithNested, getFormsByOrganisation, getKpisForm, getToReview, getFormLibraryCategories), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, saveForm, issueForm, approveForm, obsoleteForm), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, resetEntity, resetEntities, resetAllEntity } = FormSlice.actions;

// Reducer
export default FormSlice.reducer;
