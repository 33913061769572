import { IAuditEvent } from "../audit-event.model";

export const getLatestUniqueAuditEvents = (audits: IAuditEvent[]) => {
  const latestAudits: { [key: string] : IAuditEvent } = {};
  for (const audit of audits) {
    const auditCompleteType = getAuditCompleteType(audit.event);
    latestAudits[auditCompleteType] ??= audit;

    const current = latestAudits[auditCompleteType];
    if (audit.time > current.time) {
      latestAudits[auditCompleteType] = audit;
    }
  }
  return latestAudits;
}

const getAuditCompleteType = (eventMessage: string) => {
  return eventMessage.split('$')[0] + '$';
}

export const getAuditCompleteMessage = (auditCompleteType: string, extras?: string) => {
  const suffix = extras ? '>>' + extras : '';
  return `${auditCompleteType}${suffix}`;
}

export const extractAuditEventMessageExtras = (auditEvent: IAuditEvent) => {
  return auditEvent?.event.split('>>')?.[1] ?? '';
}

export const AuditCompleteTypes = Object.freeze({
  FARM_MODULE: "#FARM", // category
  FARM_BUSINESS_DETAILS: "#FARM_BUSINESS_DETAILS$",
  FARM_LIVESTOCK_GROUPS: "#FARM_LIVESTOCK_GROUPS$",
  FARM_POLICIES: "#FARM_POLICIES$",

  TEAM_MODULE: "#TEAM", // category
  TEAM_VET_LINKED: "#TEAM_VET_LINKED$",
  TEAM_UPDATED: "#TEAM_UPDATED$",
  TEAM_CATCH_UP: "#TEAM_CATCH_UP$",
  TEAM_COMPETENCY: "#TEAM_COMPETENCY$",

  VET_MODULE: "#VET", // category
  VET_VISITED: "#VET_VISITED$",
  VET_APPROVAL: "#VET_APPROVAL$",
  VET_MEDICINE_REVIEW: '#VET_MEDICINE_REVIEW$',
  VET_PERFORMANCE_REVIEW: '#VET_PERFORMANCE_REVIEW$',

  HEALTH_PLANNING: "#HEALTH_PLANNING", // category
  HEALTH_PLANNING_OVERALL: "#HEALTH_PLANNING_OVERALL$",
  HEALTH_PLANNING_PRODUCTION: "#HEALTH_PLANNING_PRODUCTION$",
  HEALTH_PLANNING_INF_DISEASE: "#HEALTH_PLANNING_INF_DISEASE$",
  HEALTH_PLANNING_FOCUS_AREAS: "#HEALTH_PLANNING_FOCUS_AREAS$",
});
