import {faCaretDown, faCaretUp, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {Button, ButtonToggle, Collapse, NavItem, NavLink} from "reactstrap";
import {NavLink as RRNavLink, useHistory, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {ReactChild, useState, useEffect} from "react";
import {useToggle} from "app/hooks/useToggle";

interface SidebarItemProps {
  to: string;
  text: string;
  icon: IconDefinition;
  datacy?: string;
  id?: string;
}

export const SidebarItem = ({to, text, icon, datacy, id}: SidebarItemProps) => {
  return (
    <NavItem>
      <NavLink
        tag={RRNavLink}
        exact={to === '/'}
        to={to}
        activeClassName='active-sidebar-link'
        className='p-3'
        data-cy={datacy}
        id={id}
      >
        <FontAwesomeIcon icon={icon} fixedWidth/>{text}
      </NavLink>
    </NavItem>
  );
}
interface SidebarButtonProps {
  handleClick: () => void;
  text: string;
  icon: IconDefinition;
  active: boolean;
  datacy?: string;
  id?: string;
  pulse?: boolean;
}
export const SidebarButton = ({handleClick, datacy, id, active, icon, text, pulse}: SidebarButtonProps) => {

  const [bounce, setBounce] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setBounce(pulse);
    const interval = setInterval(() => setBounce(false), 3000);
    return () => clearInterval(interval);
  }, [pulse, history.location]);

  return (
    <NavItem>
      <NavLink
        tag={Button}
        onClick={handleClick}
        data-cy={datacy}
        id={id}
        block
        color='white'
        size='lg'
        className={'text-start p-3 d-flex' + (active ? ' active-sidebar-link' : '')}
        >
          <span className={pulse ? '' : 'text-muted'}>
          <FontAwesomeIcon icon={icon} fixedWidth bounce={bounce} className='me-2' />{text}
          </span>
        </NavLink>
    </NavItem>
  );
}

interface SidebarSubMenuProps {
  text: string;
  icon: IconDefinition;
  children: ReactChild | ReactChild[];
  id?: string;
  datacy?: string;
  path: string;
  /** the top level button should remain highlighted for nested paths i.e. `${path}/some/child/route` */
  highlightChildren?: boolean;
  /** a list of routes where the top level button should not be highlighted, e.g. ['route'] maps to `${path}/${route}` */
  excludeChildren?: string[];
}

export const SidebarSubMenu = ({text, icon, children, datacy, id, path, highlightChildren, excludeChildren = [], }: SidebarSubMenuProps) => {

  const location = useLocation();
  const isExactMatch = location.pathname === path;
  const isLocation = location.pathname.startsWith(path + '/');
  const isExact = isExactMatch || (isLocation && highlightChildren && !excludeChildren.some(p => location.pathname.startsWith(path + '/' + p)));
  const [collapsed, toggleCollapsed] = useToggle(isLocation);
  const isCollapsed = (isLocation && !isExact) || collapsed;
  const handleClick = () => {
    if (isLocation && !isExact) return;
    toggleCollapsed();
  }

  return (
    <NavItem>
      <div className={`d-flex ${isExact ? 'active-sidebar-link' : ''}`}>
        <NavLink className={`p-3 flex-grow-1 ${isExact ? 'text-white' : 'text-dark'}`} data-cy={datacy} id={id} tag={RRNavLink} to={path}>
          <FontAwesomeIcon icon={icon} fixedWidth />
          <span>{text}</span>
        </NavLink>
        <div style={{ width: '.15em', height: '2em', opacity: 0.75 }} className={`${isExact ? 'bg-white' : 'bg-dark'} align-self-center`} />
        <ButtonToggle onClick={handleClick} color='white' disabled={isLocation && !isExact} data-cy={`${datacy}-toggle`} aria-label={`${text} menu toggle`}>
          <FontAwesomeIcon icon={!isCollapsed ? faCaretDown : faCaretUp} fixedWidth size='lg' className={`${isExact ? 'text-white' : 'text-dark'}`}  />
        </ButtonToggle>
      </div>
      <Collapse isOpen={isCollapsed}>
        <ul className='list-unstyled'>
          {children}
        </ul>
      </Collapse>
    </NavItem>
  );
}

interface SidebarSubMenuItemProps {
  to: string;
  text: string;
  icon: IconDefinition;
  externalLink?: true;
  datacy?: string;
  id?: string;
}

export const SidebarSubMenuItem = ({to, text, icon, externalLink, datacy, id}: SidebarSubMenuItemProps) => {
  const anchor: React.ElementType = 'a';
  const linkProps = {
    ...({tag: externalLink ? anchor : RRNavLink}),
    ...(externalLink && {target: ' _tab'}),
    ...(externalLink ? {href: to} : {to}),
    ...(!externalLink && {activeClassName: ' active-submenu-link', exact: false}),
  }
  return (
    <NavItem>
      <NavLink
        {...linkProps}
        className=' p-4 pt-2 pb-2 submenu-link'
        data-cy={datacy}
        id={id}
      >
        <FontAwesomeIcon icon={icon} fixedWidth/>{text}
      </NavLink>
    </NavItem>
  );
}
