import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';
import LoadingBar from 'react-redux-loading-bar';

import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';

import {useAppDispatch, useAppSelector} from 'app/config/store';
import {getSession} from 'app/shared/reducers/authentication';
import {getProfile} from 'app/shared/reducers/application-profile';
import ErrorBoundary from 'app/shared/error/error-boundary';
import AppRoutes from 'app/routes';
import {Navigation} from "app/shared/layout/navigation/Navigation";
import { getEntity as getAppUser } from './entities/app-user/app-user.reducer';
import { getEntity } from './entities/preferences/preferences.reducer';
import { useIsAdmin } from './hooks/useIsAdmin';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [isAdmin] = useIsAdmin();
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const accountId = useAppSelector(state => state.authentication.account.id);

  useEffect(() => {
    if (accountId === undefined) return;
    dispatch(getAppUser(accountId));
    dispatch(getEntity())
  }, [accountId]);
  

  return (
    <Router basename={baseHref}>
      <div className="app-container d-flex">
        <LoadingBar className="loading-bar"/>
        <Navigation
          isAuthenticated={isAuthenticated}
          isAdmin={isAdmin}
          ribbonEnv={ribbonEnv}
          isInProduction={isInProduction}
          isOpenAPIEnabled={isOpenAPIEnabled}
        >
          <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            className="toastify-container"
            toastClassName="toastify-toast"
            autoClose={3950}
          />
          <ErrorBoundary>
            <AppRoutes/>
          </ErrorBoundary>
        </Navigation>
      </div>
    </Router>
  );
};

export default App;
