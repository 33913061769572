import dayjs from 'dayjs';
import { IHerd } from 'app/shared/model/herd.model';

export interface IRoutineTreatment {
  id?: number;
  condition?: string;
  productUsed?: string;
  tags?: string | null;
  timeOfTreatment?: string;
  comments?: string | null;
  obsolete?: boolean | null;
  createdDate?: string;
  editedDate?: string;
  herd?: IHerd | null;
}

export const defaultValue: Readonly<IRoutineTreatment> = {
  obsolete: false,
};

export const defaultRoutineTreatmentConditions = [
  'Bluetongue',
  'BVD',
  'Clostridial Disease',
  'Coccidiosis',
  'Diarrhoea',
  'Digital Dermatitis',
  'Flies',
  'Fluke',
  'Gutworms',
  'IBR',
  'Leptospirosis',
  'Lice',
  'Lungworm',
  'Mastitis',
  'Navel Ill / Joint Ill',
  'Pneumonia',
  'Ringworm',
  'Tick Diseases',
  'Trace element deficiency  ',
]

export const isRoutineTreatment = (value: any): value is IRoutineTreatment => {
  return (
    typeof value === 'object' && 
    typeof value.id === 'number' &&
    typeof value.condition === 'string' &&
    typeof value.productUsed === 'string' && 
    typeof value.timeOfTreatment === 'string' &&
    typeof value.createdDate === 'string' &&
    typeof value.obsolete === 'boolean'
  );
}
