import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import { useToggle } from 'app/hooks/useToggle';
import { useIsAdmin } from 'app/hooks/useIsAdmin';

const accountMenuItemsAuthenticated = (isAdminSuperView = false) => (
  <>
    {isAdminSuperView ? (
      <div className='px-3 text-muted mb-4'>
        Exit Super View to manage the admin account settings.
      </div>
    ) : (
      <>
        <MenuItem icon="wrench" to="/account/settings" data-cy="settings">
          Settings
        </MenuItem>
        <MenuItem icon="lock" to="/account/password" data-cy="passwordItem">
          Password
        </MenuItem>
      </>
    )}
    <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
      Sign out
    </MenuItem>
  </>
);

const accountMenuItems = () => (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
      Sign in
    </MenuItem>
    <MenuItem icon="user-plus" to="/account/register" data-cy="register">
      Register
    </MenuItem>
  </>
);

const accountMenuIcon = (isAuthenticated: boolean, account: any) => {
  const userIcon = <FontAwesomeIcon className="mx-2" icon="user" size="lg" />;
  if (!isAuthenticated) return userIcon;
  if (!account?.firstName || !account?.lastName) return userIcon;

  const lastInitials = account?.lastName.split(/[-\s]/).map(n => n.charAt(0));

  return (
    <span>
      <strong>
        {userIcon}{account.firstName} {lastInitials}
      </strong>
    </span>
  );
};

export const AccountMenu = ({ isAuthenticated = false }) => {
  const account = useAppSelector(state => state.authentication.account);
  const [isOpen, toggle] = useToggle(false);
  const [, isSuperView] = useIsAdmin();

  return (
    <Dropdown data-cy="accountMenu" isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret color="" className="neutral-dropdown-item" >
        {accountMenuIcon(isAuthenticated, account)}
      </DropdownToggle>
      <DropdownMenu>{isAuthenticated ? accountMenuItemsAuthenticated(isSuperView) : accountMenuItems()}</DropdownMenu>
    </Dropdown>
  );
};

export default AccountMenu;
