import { IForm } from 'app/shared/model/form.model';
import { IHerd } from 'app/shared/model/herd.model';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { IDocument } from 'app/shared/model/document.model';
import { Species } from 'app/shared/model/enumerations/species.model';
import { Purpose } from 'app/shared/model/enumerations/purpose.model';

export interface IFormGroup {
  id?: number;
  obsolete?: boolean;
  name?: string;
  metadata?: string | null;
  species?: Species;
  purpose?: Purpose;
  forms?: IForm[] | null;
  herds?: IHerd[];
  organisation?: IOrganisation;
  documents?: IDocument[] | null;
}

export const defaultValue: Readonly<IFormGroup> = {
  obsolete: false,
};

export interface FormGroupNote {
  note: string;
  date: string;
  author: string;
}
