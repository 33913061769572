import { useToggle } from 'app/hooks/useToggle';
import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const YEARS = [...Array(81).keys()].map(x => x + 2020); // [2020, 2021, ..., 2099, 2100]

export const MonthPicker = ({ displayDate, _minDate, _maxDate, onChangeMonth, onChangeYear }) => {
  const [month, setMonth] = useState(new Date(displayDate).getMonth());
  const [year, setYear] = useState(new Date(displayDate).getFullYear());

  useEffect(() => {
    setMonth(new Date(displayDate).getMonth());
    setYear(new Date(displayDate).getFullYear());
  }, [displayDate]);

  const handleChangeMonth = (m: string) => {
    const index = MONTH_NAMES.indexOf(m);
    setMonth(index);
    onChangeMonth(index);
  }

  const handleChangeYear = (y: number) => {
    setYear(y);
    onChangeYear(y);
  }

  return (
    <div style={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap', width: '100%' }}>
      <div style={{ flex: '50%' }}>
        <Picker list={MONTH_NAMES} value={MONTH_NAMES[month]} onChange={handleChangeMonth} />
      </div>
      <div style={{ flex: '50%' }}>
        <Picker list={YEARS} value={year} onChange={handleChangeYear} />
      </div>
    </div>
  );
};

interface PickerProps<T> {
  list: T[];
  onChange: (value: T) => void;
  value: T;
}

const Picker = <T extends string | number>({ list, onChange, value }: PickerProps<T>) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useToggle(false);

  useEffect(() => {
    if (!isOpen || !divRef.current) return;
    const selectedItem = divRef.current.querySelector<HTMLButtonElement>('button[data-selected="true"]');
    if (!selectedItem) return;
    divRef.current.scrollTop = selectedItem.offsetTop - (divRef.current.clientHeight / 4);
  }, [isOpen, divRef]);

  const displayValue = typeof value === 'string' ? value.slice(0, 3) : value;

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret color='light' block className='d-flex justify-content-between align-items-center'>
        <h6 className='m-0'>
          {displayValue}
        </h6>
      </DropdownToggle>
      <DropdownMenu className='p-0'>
        <div ref={divRef} style={{ minHeight: '200px', maxHeight: '40vh', overflowY: 'auto' }} className='py-2'>
          {list.map(item => {
            const isSelected = item === value;
            return (
              <DropdownItem
                key={item}
                data-selected={isSelected}
                onClick={() => onChange(item)}
                className={isSelected ? 'bg-secondary' : ''}
              >
                {isSelected ? <strong>{item}</strong> : item}
              </DropdownItem>
            );
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}
