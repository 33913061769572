import { IHerd } from 'app/shared/model/herd.model';
import { StockType } from 'app/shared/model/enumerations/stock-type.model';

export interface IStock {
  id?: number;
  stockNumber?: number;
  stockType?: StockType;
  herd?: IHerd;
}

export const defaultValue: Readonly<IStock> = {};
