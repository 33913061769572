import { IVetPractice } from 'app/shared/model/vet-practice.model';

export interface IStripeDetails {
  id?: number;
  customerId?: string | null;
  setupIntentId?: string | null;
  paymentMethodId?: string | null;
  subscriptionId?: string | null;
  vetPractice?: IVetPractice | null;
}

export const defaultValue: Readonly<IStripeDetails> = {};
