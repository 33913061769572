import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ITarget, defaultValue } from 'app/shared/model/target.model';

const initialState: EntityState<ITarget> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/targets';

// Actions

export const getEntities = createAsyncThunk('target/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<ITarget[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'target/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ITarget>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'target/create_entity',
  async (entity: ITarget, thunkAPI) => {
    const result = await axios.post<ITarget>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'target/update_entity',
  async (entity: ITarget, thunkAPI) => {
    const result = await axios.put<ITarget>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'target/partial_update_entity',
  async (entity: ITarget, thunkAPI) => {
    const result = await axios.patch<ITarget>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'target/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ITarget>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getTargetsByFarm = createAsyncThunk(
  'target/get_all_by_farm',
  async ({ farmId, includeComplete, recent }: { farmId: string | number, includeComplete?: boolean, recent?: boolean }) => {
    const requestUrl = `${apiUrl}/farm/${farmId}`;
    return axios.get<ITarget[]>(requestUrl, { params: { includeComplete, recent } });
  },
  { serializeError: serializeAxiosError }
);

export const completeTarget = createAsyncThunk(
  'target/complete',
  async ({target}: {target: ITarget}) => {
    const requestUrl = `${apiUrl}/complete/${target.id}`;
    return axios.patch<ITarget>(requestUrl, cleanEntity(target));
  },
  { serializeError: serializeAxiosError }
);

export const getTargetsByForm = createAsyncThunk(
  'target/get_all_by_form',
  async ({ formId }: {formId: string | number}) => {
    const requestUrl = `${apiUrl}/form/${formId}`;
    return axios.get<ITarget[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const TargetSlice = createEntitySlice({
  name: 'target',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getTargetsByFarm, getTargetsByForm), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, completeTarget), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getTargetsByFarm, getTargetsByForm), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, completeTarget), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, resetEntities } = TargetSlice.actions;

// Reducer
export default TargetSlice.reducer;
