import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IDocument, defaultValue } from 'app/shared/model/document.model';
import { DocumentUploadDto } from 'app/shared/model/dto/DocumentUploadDto.model';
import { documentCategories } from 'app/modules/library/library-detail/library-utils';

const initialState: EntityState<IDocument> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  headers: {},
};

const apiUrl = 'api/documents';

// Actions

export const getEntities = createAsyncThunk('document/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IDocument[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'document/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IDocument>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesForOrganisation = createAsyncThunk(
  'document/fetch_entity_list_by_organisation',
  async (organisationId: string | number) => {
    const requestUrl = `${apiUrl}/organisation/${organisationId}?cacheBuster=${new Date().getTime()}`;
    return axios.get<IDocument[]>(requestUrl);
  }
);

export const getEntitiesForCompetency = createAsyncThunk(
  'document/fetch_entity_list_by_competency',
  async (competencyId: string | number) => {
    const requestUrl = `${apiUrl}/competency/${competencyId}`;
    return axios.get<IDocument[]>(requestUrl);
  }
);

export const getDocumentsForForm = createAsyncThunk('document/fetch_entity_list_by_form', async (formId: string | number) => {
  const requestUrl = `${apiUrl}/form/${formId}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IDocument[]>(requestUrl);
});

export const getDocumentsForFormGroup = createAsyncThunk(
  'document/fetch_entity_list_by_form_group',
  async (formGroupId: string | number) => {
    const requestUrl = `${apiUrl}/formGroup/${formGroupId}?cacheBuster=${new Date().getTime()}`;
    return axios.get<IDocument[]>(requestUrl);
  }
);

export const uploadEntity = createAsyncThunk(
  'document/upload_entity',
  async ({ dto, competencyId, farmId }: { dto: DocumentUploadDto; competencyId?: number | string; farmId: number | string }, thunkAPI) => {
    const requestUrl = `${apiUrl}/upload/${farmId}`;
    const result = await axios.post<IDocument>(requestUrl, cleanEntity(dto), { params: { competencyId } });
    thunkAPI.dispatch(getLibraryCategories(farmId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const downloadEntity = createAsyncThunk(
  'document/download_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/download/${id}`;
    return axios.get<IDocument>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'document/create_entity',
  async (entity: IDocument, thunkAPI) => {
    const result = await axios.post<IDocument>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'document/update_entity',
  async (entity: IDocument, thunkAPI) => {
    const result = await axios.put<IDocument>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'document/partial_update_entity',
  async (entity: IDocument, thunkAPI) => {
    const result = await axios.patch<IDocument>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'document/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IDocument>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getLibraryCategories = createAsyncThunk('document/library_categories', async (farmId: string | number) => {
  const requestUrl = `${apiUrl}/categories/${farmId}`;
  return axios.get<IDocument>(requestUrl);
});

export const getFilteredDocuments = createAsyncThunk(
  'document/filtered_documents',
  async (
    { id, param, showObsolete }: { id: string | number; param: { name: string; value: string | number }; showObsolete?: boolean },
    thunkAPI
  ) => {
    const args = `?showObsolete=${showObsolete}&${param.name}=${param.value}`;
    const requestUrl = `${apiUrl}/filter/${id}${args}`;
    const result = await axios.get<IDocument[]>(requestUrl);
    thunkAPI.dispatch(getLibraryCategories(id));
    return result;
  }
);

export const obsoleteDocument = createAsyncThunk(
  'document/toggle_obsolete_entity',
  async ({ entity, farmId }: { entity: IDocument; farmId: string | number }, thunkAPI) => {
    const result = await axios.patch<IDocument>(`${apiUrl}/obsolete/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getLibraryCategories(farmId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const generateModuleSummary = createAsyncThunk(
  'document/download_module_summary',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/module-summary/${id}`;
    return axios.get<IDocument>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const DocumentSlice = createEntitySlice({
  name: 'document',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getLibraryCategories.fulfilled, (state, action) => {
        const headers = {
          totalCount: parseInt(action.payload.headers['x-library-documents-totalcount'], 10),
          byType: parseInt(action.payload.headers['x-library-documents-by-type'], 10),
          targets: parseInt(action.payload.headers['x-library-documents-targets'], 10),
          healthPlans: parseInt(action.payload.headers['x-library-documents-healthplans'], 10),
          focusAreas: parseInt(action.payload.headers['x-library-documents-focusareas'], 10),
          diseases: parseInt(action.payload.headers['x-library-documents-diseases'], 10),
          vetReviews: parseInt(action.payload.headers['x-library-documents-vetreviews'], 10),
          catchUps: parseInt(action.payload.headers['x-library-documents-catchups'], 10),
          farmPolicies: parseInt(action.payload.headers['x-library-documents-farm-policies'], 10),
          misc: {},
        };
        for (const type of documentCategories) {
          headers.misc[type] = parseInt(action.payload.headers[`x-library-documents-type-${type.toLowerCase()}`], 10);
        }
        state.headers = {
          ...state.headers,
          ...headers,
        };
        state.updateSuccess = true;
        state.updating = false;
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesForOrganisation, getDocumentsForForm, getFilteredDocuments, getEntitiesForCompetency), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, uploadEntity, obsoleteDocument), (state, action) => {
        const entity = action.payload.data;
        const entities = state.entities.filter(e => e.id !== entity.id);
        entities.push(entity);
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = entity;
        state.entities = entities;
      })
      .addMatcher(isFulfilled(downloadEntity, generateModuleSummary), (state, action) => {
        state.loading = false;
        const {
          data: { contentsContentType, contents, filename },
        } = action.payload;
        downloadFile(contentsContentType, contents, filename);
      })
      .addMatcher(
        isPending(
          getEntities,
          getEntity,
          downloadEntity,
          getEntitiesForOrganisation,
          getDocumentsForForm,
          getEntitiesForCompetency,
          getFilteredDocuments,
          generateModuleSummary
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, uploadEntity, getLibraryCategories, obsoleteDocument),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset, resetAllEntity } = DocumentSlice.actions;

// Reducer
export default DocumentSlice.reducer;

const downloadFile = (contentType: string, contents: string, filename: string) => {
  const link = document.createElement('a');
  link.href = `data:${contentType};base64,${contents}`;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
