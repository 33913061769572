import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IAuditEvent, defaultValue } from 'app/shared/model/audit-event.model';
import { AuditCompleteTypes } from 'app/shared/model/constants/AuditCompleteTypes';

const initialState: EntityState<IAuditEvent> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/audit-events';

// Actions

export const getEntities = createAsyncThunk('auditEvent/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IAuditEvent[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'auditEvent/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAuditEvent>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'auditEvent/create_entity',
  async (entity: IAuditEvent, thunkAPI) => {
    const result = await axios.post<IAuditEvent>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'auditEvent/update_entity',
  async (entity: IAuditEvent, thunkAPI) => {
    const result = await axios.put<IAuditEvent>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'auditEvent/partial_update_entity',
  async (entity: IAuditEvent, thunkAPI) => {
    const result = await axios.patch<IAuditEvent>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'auditEvent/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IAuditEvent>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getAuditEventsForYear = createAsyncThunk(
  'auditEvent/get_all_for_year',
  async ({farmId, type, unique}: {farmId: string | number, type?: string, unique?: boolean}) => {
    const uniqueParam = unique ? unique : false;
    const typeParam = type ? `&type=${encodeURIComponent(type)}` : '';
    const requestUrl = `${apiUrl}/year/${farmId}?unique=${uniqueParam}${typeParam}`;
    return axios.get<IAuditEvent[]>(requestUrl);
  }
);

type VetAreaAction = 'vet-visit' | 'vet-approval' | 'medicine-review' | 'performance-review';

export const performVetAction = createAsyncThunk(
  'auditEvent/record_vet_action',
  async({vetAuditEvent, path}: {vetAuditEvent: any, path: VetAreaAction}, thunkAPI) => {
    const requestUrl = `${apiUrl}/${path}`;
    vetAuditEvent.auditEvent = cleanEntity(vetAuditEvent.auditEvent)
    const result = await axios.post<IAuditEvent>(requestUrl, vetAuditEvent);
    thunkAPI.dispatch(getAuditEventsForYear({farmId: vetAuditEvent.auditEvent.organisation.id, type: AuditCompleteTypes.VET_MODULE, unique: true}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const AuditEventSlice = createEntitySlice({
  name: 'auditEvent',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getAuditEventsForYear), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, performVetAction), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getAuditEventsForYear), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, performVetAction), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, resetAllEntity } = AuditEventSlice.actions;

// Reducer
export default AuditEventSlice.reducer;
