import dayjs from 'dayjs';
import { IForm } from 'app/shared/model/form.model';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { ICompetency } from 'app/shared/model/competency.model';
import { IFormGroup } from 'app/shared/model/form-group.model';

export interface IDocument {
  id?: number;
  type?: string;
  contentsContentType?: string;
  contents?: string;
  filetype?: string;
  generated?: boolean;
  uploadDate?: string;
  expirationDate?:string;
  owner?: string;
  obsolete?: boolean;
  filename?: string;
  description?: string | null;
  form?: IForm | null;
  organisation?: IOrganisation | null;
  competency?: ICompetency | null;
  formGroup?: IFormGroup | null;
}

export const defaultValue: Readonly<IDocument> = {
  generated: false,
  obsolete: false,
};
