import { IFarmDetails } from 'app/shared/model/farm-details.model';
import { IMembership } from 'app/shared/model/membership.model';
import { IEvent } from 'app/shared/model/event.model';
import { IVetPractice } from 'app/shared/model/vet-practice.model';
import { IConsultancy } from 'app/shared/model/consultancy.model';
import { IForm } from 'app/shared/model/form.model';
import { IDocument } from 'app/shared/model/document.model';
import { IFormGroup } from 'app/shared/model/form-group.model';
import { OrganisationType } from 'app/shared/model/enumerations/organisation-type.model';

export interface IOrganisation {
  id?: number;
  activated?: boolean;
  organisationType?: OrganisationType;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactMobile?: string | null;
  logoContentType?: string | null;
  logo?: string | null;
  farmDetails?: IFarmDetails | null;
  memberships?: IMembership[] | null;
  events?: IEvent[] | null;
  childMemberships?: IMembership[] | null;
  vetPracticeDetails?: IVetPractice | null;
  nameOfOrganisation?: string | null;
  consultancy?: IConsultancy | null;
  forms?: IForm[] | null;
  attendingEvents?: IEvent[] | null;
  documents?: IDocument[] | null;
  formGroups?: IFormGroup[] | null;
}

export const defaultValue: Readonly<IOrganisation> = {
  activated: false,
};
