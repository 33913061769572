import React, {useEffect, useState} from 'react';
import {faBell} from '@fortawesome/free-regular-svg-icons';
import {
  faBell as faBellSolid,
  faChevronLeft,
  faChevronRight,
  faCircle
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {
  getNotificationUnreadCount,
  getPage,
  markAllRead, markRead
} from 'app/entities/event/notification.reducer';
import {IEvent} from 'app/shared/model/event.model';
import {DESC} from 'app/shared/util/pagination.constants';
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {EventStatus} from 'app/shared/model/enumerations/event-status.model';

export const Notifications = () => {

  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const {
    entities,
    pages,
    totalUnread,
    totalItems,
    currentPage,
    pageSize,
    errorMessage
  } = useAppSelector(state => state.notification);
  const notifications: IEvent[] = pages[currentPage]?.map(p => entities[p]) || [];

  const hasUnread = totalUnread > 0;
  const totalPages = Math.ceil(totalItems / pageSize);

  const [page, setPage] = useState(0);
  const [shouldShake, setShouldShake] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // initial notification fetch, updated on pagination
  useEffect(() => {
    dispatch(getPage({page, size: 10, sort: DESC}));
  }, [page]);

  useEffect(() => {
    document.title = `HerdPlan ${ totalUnread > 0 ? '(' + totalUnread + ')' : '' }`;
    return () => {
      document.title = 'HerdPlan';
    }
  }, [totalUnread])

  // reset the page to 0 after a minute
  // and fetch notifications on open
  useEffect(() => {
    if (isOpen) {
      dispatch(getPage({page, size: 10, sort: DESC}));
    }
    if (isOpen || page === 0) return;
    const timer = setTimeout(() => setPage(0), 60000);
    return () => clearTimeout(timer)
  }, [isOpen]);

  // continue refetching unread count unless list is open
  useEffect(() => {
    const interval = setInterval(() => {
      if (isOpen || errorMessage) return;
      dispatch(getNotificationUnreadCount());
    }, 60000);
    return () => clearInterval(interval);
  }, [isOpen, errorMessage]);

  // shake the bell icon for 3s when unread notifications appear
  useEffect(() => {
    if (!hasUnread) return;
    setShouldShake(true);
    const timer = setTimeout(() => setShouldShake(false), 3000);

    return () => {
      setShouldShake(false);
      clearTimeout(timer);
    }
  }, [hasUnread]);

  const handleDropdownToggle = (e, dropdownIsOpen) => {
    setIsOpen(dropdownIsOpen);
  }

  const handleMarkAllReadNotifications = () => {
    dispatch(markAllRead(account.id));
  }
  const handleMarkReadNotifications = (entity) => {
    if (entity.status !== EventStatus.COMPLETE) {
      dispatch(markRead(entity.id));
    }
  }

  const getUnreadCount = () => {
    if (notifications.length === 0) return null;
    return (
      <div className='d-flex flex-row flex-nowrap align-items-end justify-content-between border-bottom pb-2 mb-2'>
        {hasUnread &&
          <Button
            data-cy="mark_read_btn"
            color='link'
            onClick={handleMarkAllReadNotifications}
          >Mark all as read</Button>
        }
        <div></div>
        <div className='text-end p-2 ps-4 pe-4'>{totalUnread} unread</div>
      </div>
    );
  }

  const getPaginationButtons = () => {
    if (notifications.length === 0) return null;
    return (
      <div className='d-flex mt-2 border-top pt-2'>
        <Button
          data-cy='previous-notification-list-page'
          color='link'
          block
          disabled={currentPage === 0}
          onClick={() => setPage(page - 1)}
        >
          <FontAwesomeIcon icon={faChevronLeft}/>
        </Button>
        <Button
          data-cy='next-notification-list-page'
          color='link'
          block
          onClick={() => setPage(page + 1)}
          disabled={currentPage + 1 >= totalPages}
        >
          <FontAwesomeIcon icon={faChevronRight}/>
        </Button>
      </div>
    );
  }

  const getNoNotificationsMessage = () => {
    if (notifications.length !== 0) return null;
    return (
      <DropdownItem data-cy='dropdown-no-item'>
        There&apos;s nothing here!
      </DropdownItem>
    );
  }

  return (
    <>
      <UncontrolledDropdown
        nav
        id='notifications-dropdown'
        data-cy='notifications-dropdown'
        className='p-0'
        onToggle={handleDropdownToggle}
      >
        <DropdownToggle nav caret className="d-flex align-items-center" aria-label='Notifications'>
          <span className='fa-layers fa-fw'>
            {hasUnread && (
              <FontAwesomeIcon
                data-cy='dot-bell'
                icon={faCircle}
                className='text-primary'
                transform={{x: 22, y: -12, size: 14}}
              />
            )}
            <FontAwesomeIcon
              data-cy={hasUnread ? 'solid-bell' : 'empty-bell'}
              icon={hasUnread ? faBellSolid : faBell}
              transform={{x: 8}}
              size='lg'
              shake={shouldShake}
              style={{'--fa-animation-duration': '1.5s', display: 'block'} as React.CSSProperties}
            />
          </span>
          <span>&nbsp;</span>
        </DropdownToggle>
        <DropdownMenu end style={{width: '300px', overflowY:'auto', maxHeight:'80vh'}}>
          {getUnreadCount()}
          {notifications.map(notification =>
            <DropdownItem
                data-cy="dropdown-item"
                key={notification.id}
                style={{ whiteSpace: 'pre-wrap' }}
                onClick={() => handleMarkReadNotifications(notification)}
                toggle={false}
              >
                <div
                  className="d-flex"
                >
                  <div style={{ width: '20px', minWidth: '20px' }}>
                    {notification.status !== EventStatus.COMPLETE && (
                      <FontAwesomeIcon
                        data-cy="dot-bell-list"
                        icon={faCircle}
                        className="text-primary"
                        transform={{ size: 10 }}
                      />
                    )}
                  </div>
                  <span>{notification.title}</span>
                </div>
              </DropdownItem>
          )}
          {getPaginationButtons()}
          {getNoNotificationsMessage()}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}
