import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IAction, defaultValue } from 'app/shared/model/action.model';

const initialState: EntityState<IAction> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/actions';

// Actions

export const getActionsForForm = createAsyncThunk(
  'action/fetch_entity_list_by_form',
  async (formId: number | string) => {
    const requestUrl = `${apiUrl}/form/${formId}`;
    return axios.get<IAction[]>(requestUrl);
  }
);

export const reviewActionComplete = createAsyncThunk(
  'action/review_complete',
  async (action: IAction, thunkApi) => {
    const requestUrl = `${apiUrl}/review/${action.id}`;
    const result = await axios.patch<IAction>(requestUrl, cleanEntity(action));
    thunkApi.dispatch(getActionsForForm(action.form.id));
    return result;
  }
)

export const getEntities = createAsyncThunk('action/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IAction[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'action/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAction>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'action/create_entity',
  async (entity: IAction, thunkAPI) => {
    const result = await axios.post<IAction>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'action/update_entity',
  async (entity: IAction, thunkAPI) => {
    const result = await axios.put<IAction>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'action/partial_update_entity',
  async (entity: IAction, thunkAPI) => {
    const result = await axios.patch<IAction>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'action/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IAction>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getCatchUpActions = createAsyncThunk(
  'action/catch_up',
  async ({ farmId, monthsBack }: { farmId: string | number, monthsBack?: number }) => {
    const requestUrl = `${apiUrl}/catch_up/${farmId}`;
    const queryString = monthsBack !== undefined ? `?monthsBack=${monthsBack}` : '';
    return axios.get<IAction[]>(requestUrl + queryString);
  }
);

export const catchUpActionComplete = createAsyncThunk(
  'action/catch_up_complete',
  async (action: IAction) => {
    const requestUrl = `${apiUrl}/review/${action.id}`;
    return axios.patch<IAction>(requestUrl, cleanEntity(action));
  }
);


// slice

export const ActionSlice = createEntitySlice({
  name: 'action',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getActionsForForm, getCatchUpActions), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, reviewActionComplete, catchUpActionComplete), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getActionsForForm, getCatchUpActions), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, reviewActionComplete, catchUpActionComplete), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, resetEntity,resetAllEntity, resetEntities } = ActionSlice.actions;

// Reducer
export default ActionSlice.reducer;
