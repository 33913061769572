import axios from 'axios';
import { PayloadAction, createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, getThunkState } from 'app/shared/reducers/reducer.utils';
import { IDataSheet, defaultValue } from 'app/shared/model/data-sheet.model';

const initialState: EntityState<IDataSheet> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/data-sheets';

// Actions

export const getEntities = createAsyncThunk('dataSheet/fetch_entity_list', async ({ farmId, includeArchived }: { farmId: number, includeArchived?: boolean | undefined }, thunkAPI) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  thunkAPI.dispatch(setIncludeArchived(includeArchived));
  return axios.get<IDataSheet[]>(requestUrl, { params: { farmId, includeArchived } });
});

export const getEntity = createAsyncThunk(
  'dataSheet/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IDataSheet>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'dataSheet/create_entity',
  async ({ entity, farmId, herdId }: { entity: IDataSheet, farmId: number, herdId: number }, thunkAPI) => {
    const result = await axios.post<IDataSheet>(apiUrl, cleanEntity(entity), { params: { herdId } });
    const includeArchived = getThunkState(thunkAPI)?.dataSheet?.headers?.includeArchived;
    thunkAPI.dispatch(getEntities({ farmId, includeArchived }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'dataSheet/partial_update_entity',
  async ({ entity, farmId }: { entity: IDataSheet, farmId: number }, thunkAPI) => {
    const result = await axios.patch<IDataSheet>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    const includeArchived = getThunkState(thunkAPI)?.dataSheet?.headers?.includeArchived;
    thunkAPI.dispatch(getEntities({ farmId, includeArchived }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'dataSheet/delete_entity',
  async ({ id, farmId }: { id: string | number, farmId }, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IDataSheet>(requestUrl);
    const includeArchived = getThunkState(thunkAPI)?.dataSheet?.headers?.includeArchived;
    thunkAPI.dispatch(getEntities({ farmId, includeArchived }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const DataSheetSlice = createEntitySlice({
  name: 'dataSheet',
  initialState,
  reducers: {
    setIncludeArchived: (state, action: PayloadAction<boolean | undefined>) => {
      return {
        ...state,
        headers: {
          ...state.headers,
          includeArchived: action.payload,
        },
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, setIncludeArchived, setEntity } = DataSheetSlice.actions;

// Reducer
export default DataSheetSlice.reducer;
