import axios from 'axios';
import { createAsyncThunk, createSlice, isPending, PayloadAction } from '@reduxjs/toolkit';
import { APP_DATE_ONLY_FORMAT } from 'app/config/constants';
import { CalendarFilter, getFilterId } from 'app/shared/model/dto/CalendarFilter';
import dayjs from 'dayjs';
import { IAction } from 'app/shared/model/action.model';
import { convertDateToISOString } from 'app/shared/util/date-utils';
import { getEntity } from 'app/entities/action/action.reducer';


export interface CalendarState {
  loading: boolean;
  to: string;
  from: string;
  date: string;
  people: ReadonlyArray<CalendarFilter>;
  selectedFarms: CalendarFilter[];
  selectedPeople: CalendarFilter[];
  selectedForms: CalendarFilter[];
  selectedHerds: CalendarFilter[];
  actions: ReadonlyArray<IAction>;
  modalOpen: boolean;
  calendarView: boolean;
}

const initialState: CalendarState = {
  loading: false,
  date: null,
  to: null,
  from: null,
  people: [],
  selectedFarms: [],
  selectedPeople: [],
  selectedForms: [],
  selectedHerds: [],
  actions: [],
  modalOpen: false,
  calendarView: true,
}

const apiUrl = 'api/calendar';

// actions

export const getCalendarFilterTeams = createAsyncThunk(
  'calendar/fetch_team',
  async () => {
    const requestUrl = `${apiUrl}/staff`;
    return axios.get<CalendarFilter[]>(requestUrl);
  }
);

export const getCalendarActions = createAsyncThunk(
  'calendar/fetch_actions',
  async ({ from, to }: { from: string, to: string }) => {
    const params = `from=${convertDateToISOString(from)}&to=${convertDateToISOString(to)}`;
    const requestUrl = `${apiUrl}/actions?${params}`;
    return axios.get<IAction[]>(requestUrl);
  }
);

export const launchModal = createAsyncThunk(
  'calendar/launch_modal',
  async (entity: IAction, thunkAPI) => {
    const result = await thunkAPI.dispatch(getEntity(entity.id));
    if (result.meta.requestStatus === 'fulfilled') {
      thunkAPI.dispatch(setModal(true));
    }
  }
);

// slice

const getToAndFromDates = (date: string, includeDaysEitherSide: boolean): [date: string, to: string, from: string] => {
  const from = dayjs(date).startOf('month').subtract(includeDaysEitherSide ? 6 : 0, 'day').format(APP_DATE_ONLY_FORMAT);
  const to = dayjs(date).endOf('month').add(includeDaysEitherSide ? 6 : 0, 'day').format(APP_DATE_ONLY_FORMAT);
  return [date, from, to];
}

export const CalendarSlice = createSlice({
  name: 'calendar',
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    resetFilters: (state) => {
      state.selectedFarms = [];
      state.selectedPeople = [];
      state.selectedForms = [];
      state.selectedHerds = [];
    },
    setDate: (state, action: PayloadAction<string>) => {
      const [date, from, to] = getToAndFromDates(action.payload, state.calendarView);
      if (state.calendarView) {
        state.to = to;
        state.from = from;
      }
      state.date = date;
    },
    setAgendaDate: (state, action: PayloadAction<string>) => {
      const [date, from, to] = getToAndFromDates(action.payload, false);
      state.date = date;
      state.to = to;
      state.from = from;
    },
    setFrom: (state, action: PayloadAction<string>) => {
      state.from = dayjs(action.payload).format(APP_DATE_ONLY_FORMAT);
    },
    setTo: (state, action: PayloadAction<string>) => {
      state.to = dayjs(action.payload).format(APP_DATE_ONLY_FORMAT);
    },
    selectFarm: (state, action: PayloadAction<CalendarFilter>) => {
      state.selectedFarms.push(action.payload);
    },
    deselectFarm: (state, action: PayloadAction<CalendarFilter>) => {
      state.selectedFarms = state.selectedFarms.filter(s => getFilterId(s) !== getFilterId(action.payload));
    },
    selectHerd: (state, action: PayloadAction<CalendarFilter>) => {
      state.selectedHerds.push(action.payload);
    },
    deselectHerd: (state, action: PayloadAction<CalendarFilter>) => {
      state.selectedHerds = state.selectedHerds.filter(s => getFilterId(s) !== getFilterId(action.payload));
    },
    resetHerds: (state) => {
      state.selectedHerds = [];
    },
    selectPerson: (state, action: PayloadAction<CalendarFilter>) => {
      state.selectedPeople.push(action.payload);
    },
    deselectPerson: (state, action: PayloadAction<CalendarFilter>) => {
      state.selectedPeople = state.selectedPeople.filter(s => getFilterId(s) !== getFilterId(action.payload));
    },
    selectForm: (state, action: PayloadAction<CalendarFilter>) => {
      state.selectedForms.push(action.payload);
    },
    deselectForm: (state, action: PayloadAction<CalendarFilter>) => {
      state.selectedForms = state.selectedForms.filter(s => getFilterId(s) !== getFilterId(action.payload));
    },
    setModal: (state, action: PayloadAction<boolean>) => {
      state.modalOpen = action.payload;
    },
    toggleModal: (state) => {
      state.modalOpen = !state.modalOpen;
    },
    setCalendarView: (state, action: PayloadAction<boolean>) => {
      state.calendarView = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCalendarFilterTeams.fulfilled, (state, action) => {
        state.loading = false;
        state.people = action.payload.data;
      })
      .addCase(getCalendarActions.fulfilled, (state, action) => {
        state.loading = false;
        state.actions = action.payload.data;
      })
      .addMatcher(isPending(getCalendarFilterTeams, getCalendarActions), (state) => {
        state.loading = true;
      });
  },
});

export const { reset, resetFilters, setDate, setAgendaDate, setFrom, setTo, selectFarm, deselectFarm, selectHerd, deselectHerd, resetHerds, selectPerson, deselectPerson, selectForm, deselectForm, setModal, toggleModal, setCalendarView } = CalendarSlice.actions;

export default CalendarSlice.reducer;