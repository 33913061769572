import dayjs from 'dayjs';
import { IAppUser } from 'app/shared/model/app-user.model';
import { IOrganisation } from 'app/shared/model/organisation.model';

export interface IAuditEvent {
  id?: number;
  time?: string | null;
  expiry?: string | null;
  event?: string | null;
  user?: IAppUser;
  organisation?: IOrganisation | null;
  negated?: boolean;
  expired?: boolean;
  valid?: boolean;
}

export const defaultValue: Readonly<IAuditEvent> = {};
