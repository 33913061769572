import React from "react";
import { AUTHORITIES } from "app/config/constants";
import { useAppSelector } from "app/config/store";
import { hasAnyAuthority } from "app/shared/auth/private-route";


/**
 * Hook to abstract away checking that the account authorities contain the ADMIN constant
 * @returns 
 */
export const useIsAdmin = (): [isAdmin: boolean, isSuperView: boolean] => {
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication?.account?.authorities, [AUTHORITIES.ADMIN]));
  const isSuperView = useAppSelector(state => state.appConfig?.superView);

  return [!!isAdmin, !!isSuperView];
}
