import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppConfigState {
  sidebarOpen: boolean;
  farmId?: number;
  refetchFarmCounter?: number;
  teamCatchUpActionFormIdSelection?: number;
  superView?: boolean;
}

const initialState: AppConfigState = {
  sidebarOpen: false,
  farmId: -1,
  refetchFarmCounter: 0,
  superView: false,
};

export const AppConfigSlice = createSlice({
  name: 'appConfig',
  initialState: initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean | undefined>) => {
      state.sidebarOpen = action.payload ?? !state.sidebarOpen;
    },
    setFarmId: (state, action: PayloadAction<number>) => {
      state.farmId = action.payload;
    },
    refetchFarm: state => {
      state.refetchFarmCounter = state.refetchFarmCounter + 1;
    },
    setTeamCatchUpActionFormId: (state, action: PayloadAction<number>) => {
      state.teamCatchUpActionFormIdSelection = action.payload;
    },
    toggleSuperView: (state, action: PayloadAction<boolean | undefined>) => {
      state.superView = action.payload ?? !state.superView;
    }
  },
});

export const { toggleSidebar, setFarmId, refetchFarm, setTeamCatchUpActionFormId, toggleSuperView } = AppConfigSlice.actions;

export default AppConfigSlice.reducer;
