import { useAppSelector } from "app/config/store";
import { userIsVet } from "app/shared/util/membership-util";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { useHistory } from "react-router-dom";

export interface GoToLink {
  navigate: () => void;
}

interface Props {
  setAvailable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ManualLinker = React.forwardRef<GoToLink, Props>(({ setAvailable }, ref) => {

  const appUser = useAppSelector(state => state.appUser.entity);
  const history = useHistory();
  const [href, setHref] = useState<string>(null);

  const isVet = userIsVet(appUser);

  useImperativeHandle(ref,
    () => ({
      navigate: () => {
        if (href === null) return;
        window.open(href, '_blank');
      }
    }),
    [href]
  );

  useEffect(() => {
    setAvailable(href !== null);
  }, [href]);

  useEffect(() => {
    let path = history.location.pathname;
    if (path === '/') {
      path = isVet ? vetDashboard : farmerDashboard;
    }
    if (path.startsWith('/review/vet-area')) {
      path = isVet ? vetAreaForVets : vetAreaForFarmers;
    }
    for (const [key, link] of linkEntries) {
      if (key.startsWith(path)) {
        setHref(link);
        return;
      }
    }
  }, [history.location.pathname]);

  return (
    <>
    </>
  );
})

const vetDashboard = 'vetDashboard';
const farmerDashboard = 'farmerDashboard';

const vetAreaForVets = 'vetAreaForVets';
const vetAreaForFarmers = 'vetAreaForFarmers';

const linkMapper = {
  [farmerDashboard]: 'https://manual.herdplan.co.uk/farmer-view.html',
  [vetDashboard]: 'https://manual.herdplan.co.uk/vet-view.html',

  '/my-farm': 'https://manual.herdplan.co.uk/my-farm.html',
  '/my-farm/wizard': 'https://manual.herdplan.co.uk/setting-up-a-farm.html#-alhwms_12',
  '/my-farm/animal-groups': 'https://manual.herdplan.co.uk/my-livestock-groups.html',
  '/my-farm/policies/form': 'https://manual.herdplan.co.uk/farm-policies-and-documents.html',
  '/my-farm/data-sheets': 'https://manual.herdplan.co.uk/data-sheets.html',
  '/my-farm/routine-treatments': 'https://manual.herdplan.co.uk/routine-treatments.html',

  '/my-team': 'https://manual.herdplan.co.uk/my-team.html',
  '/my-team/team-catch-up': 'https://manual.herdplan.co.uk/team-catch-up.html',

  '/my-health-plan': 'https://manual.herdplan.co.uk/my-health-plan.html',
  '/my-health-plan/production': 'https://manual.herdplan.co.uk/production.html',
  '/my-health-plan/infectious-diseases': 'https://manual.herdplan.co.uk/infectious-diseases.html',
  '/my-health-plan/focus-areas': 'https://manual.herdplan.co.uk/focus-areas.html',

  '/farm-library': 'https://manual.herdplan.co.uk/farm-library.html',
  '/my-calendar': 'https://manual.herdplan.co.uk/my-calendar.html',
  [vetAreaForVets]: 'https://manual.herdplan.co.uk/vet-area-for-vets.html',
  [vetAreaForFarmers]: 'https://manual.herdplan.co.uk/vet-area.html',

  '/my-practice': 'https://manual.herdplan.co.uk/my-practice-team.html',
  '/my-practice/payment': 'https://manual.herdplan.co.uk/subscriptions-and-contracts.html',
  '/my-templates': 'https://manual.herdplan.co.uk/my-templates.html',

  '/account/settings': 'https://manual.herdplan.co.uk/settings.html',
}

const linkEntries = Object.freeze(Object.entries(linkMapper));
