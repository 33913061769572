import React from 'react';
import {SidebarItem, SidebarSubMenuItem} from "app/shared/layout/menus/sidebar-menu-components";
import {
  faBook,
  faClipboardList,
  faCogs,
  faHeart,
  faPoll,
  faTachometerAlt,
  faTasks,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import {faDatabase} from "@fortawesome/free-solid-svg-icons/faDatabase";

const adminMenuItems = () => (
  <>

    <SidebarItem to={'/admin/dashboard'} text={'Admin Dashboard'} icon={faClipboardList}/>

    <SidebarItem to={'/admin/user-management'} text={'User Management'} icon={faUsers}/>

    <SidebarItem icon={faTachometerAlt} to='/admin/metrics' text='Metrics'/>

    <SidebarItem icon={faHeart} to='/admin/health' text='Health'/>

    <SidebarItem icon={faCogs} to='/admin/configuration' text='Configuration'/>

    <SidebarItem icon={faTasks} to='/admin/logs' text='Logs'/>

    {/* jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here */}
  </>
);

const openAPIItem = () => (
  <SidebarSubMenuItem to={'/admin/docs'} text={'API'} icon={faBook}/>
);

const databaseItem = () => (
  <SidebarSubMenuItem to={'./h2-console/'} text={'Database'} icon={faDatabase} externalLink/>
);

export const SidebarAdminMenu = ({showOpenAPI, showDatabase}) => (
  <>
    {adminMenuItems()}
    {showOpenAPI && openAPIItem()}
    {showDatabase && databaseItem()}
    <SidebarItem datacy='sb-benchmarking-groups' to='/admin/benchmarking-groups' text='Benchmarking' icon={faPoll} />
  </>
);

export default SidebarAdminMenu;
