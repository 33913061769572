import { IForm } from 'app/shared/model/form.model';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { IHerd } from 'app/shared/model/herd.model';

export interface ITarget {
  id?: number;
  category?: string;
  target?: string;
  description?: string | null;
  denominator?: number;
  unit?: string;
  operator?: string;
  value?: number;
  date?: string;
  actualDate?: string | null;
  actualValue?: number | null;
  form?: IForm | null;
  farm?: IOrganisation | null;
  herds?: IHerd[] | null;
  freeText?: boolean;
  tags?: string;
}

export const defaultValue: Readonly<ITarget> = {};
