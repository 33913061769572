import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IVetPractice, defaultValue } from 'app/shared/model/vet-practice.model';

const initialState: EntityState<IVetPractice> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/vet-practices';

// Actions

interface QueryAllPracticesParams extends IQueryParams {
  onlyFetchAvailable?: boolean;
}

export const getEntities = createAsyncThunk('vetPractice/fetch_entity_list', async ({ page, size, sort, onlyFetchAvailable, }: QueryAllPracticesParams) => {
  return axios.get<IVetPractice[]>(apiUrl, { params: { cacheBuster: new Date().getTime(), onlyFetchAvailable } });
});

export const getEntity = createAsyncThunk(
  'vetPractice/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IVetPractice>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'vetPractice/create_entity',
  async (entity: IVetPractice, thunkAPI) => {
    const result = await axios.post<IVetPractice>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'vetPractice/update_entity',
  async ({ entity, contactName }: { entity: IVetPractice, contactName?: string }, thunkAPI) => {
    const result = await axios.put<IVetPractice>(`${apiUrl}/${entity.id}`, cleanEntity(entity), { params: { contactName } });
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'vetPractice/partial_update_entity',
  async (entity: IVetPractice, thunkAPI) => {
    const result = await axios.patch<IVetPractice>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'vetPractice/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IVetPractice>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const claimPractice = createAsyncThunk(
  'vetPractice/claim',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}/claim`;
    const result = await axios.post<IVetPractice>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const VetPracticeSlice = createEntitySlice({
  name: 'vetPractice',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, claimPractice), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, claimPractice), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, resetEntity } = VetPracticeSlice.actions;

// Reducer
export default VetPracticeSlice.reducer;
