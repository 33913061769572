import dayjs from 'dayjs';
import { IHerd } from 'app/shared/model/herd.model';

export interface IDataSheet {
  id?: number;
  title?: string;
  data?: string;
  dateCreated?: string;
  archived?: boolean;
  herd?: IHerd;
}

export const defaultValue: Readonly<IDataSheet> = {
  archived: false,
};
