import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IAccess, defaultValue } from '../model/dto/Access.model';

const initialState: EntityState<IAccess> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
}

// Actions
export const getAccessList = createAsyncThunk(
  'access/get_access_list',
  async () => {
    const requestUrl = 'api/memberships/access';
    return axios.get<IAccess[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Slice
export const AccessSlice = createEntitySlice({
  name: 'access',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getAccessList), (state, action) => {
        const {data} = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isPending(getAccessList), (state, action) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = AccessSlice.actions;

// Reducer
export default AccessSlice.reducer;
