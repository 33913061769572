import { AccessLevel } from "../model/enumerations/access-level.model";
import { EventStatus } from "../model/enumerations/event-status.model";
import { EventType } from "../model/enumerations/event-type.model";
import { OrganisationType } from "../model/enumerations/organisation-type.model";
import { Priority } from "../model/enumerations/priority.model";
import { Recurring } from "../model/enumerations/recurring.model";
import { UserRole } from "../model/enumerations/user-role.model";

export const sentenceCaseEnum = (value: UserRole | EventType | Priority | EventStatus | AccessLevel | OrganisationType | Recurring | string) => {
  const spaced = (value ?? '').split('_').join(' ');
  return sentenceCase(spaced);
}

export const sentenceCase = (value: string) => {
  return (value ?? '').charAt(0).toUpperCase() + (value ?? '').slice(1).toLowerCase();
}

export const titleCase = (value: string) => {
  return (value ?? '').split(/[\s_]/).map(v => sentenceCase(v)).join(' ');
}

export const stripFormTypeCategory = (value: string) => {
  return (value ?? '__').split('__')[1] ?? value;
}

export const lowerCase = (value: string) => {
  return (value ?? '').split('_').join(' ').toLowerCase();
}

export const addSpacesBeforeCaps = (text: string) => {
  return (text || '').replace(/([A-Z][a-z])/g, ' $1')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .trim();
}

export const userRoleToOrganisationType = (role: UserRole) => {
  switch (role) {
    case UserRole.VET:
      return OrganisationType.VET_PRACTICE;
    case UserRole.CONSULTANT:
    case UserRole.NUTRITIONIST:
      return OrganisationType.CONSULTANCY;
    case UserRole.FARMER:
    case UserRole.FARM_STAFF:
    default:
      return OrganisationType.FARM;
  }
}

export const pluralise = (num: number, word: string, pluralForm = word + 's') => {
  return [-1, 1].includes(num) ? word : pluralForm;
}

export const numberOverflow = (num: number) => {
  if (num > 9) return '9+';
  return ''+num;
}

export const romanNumerals = (num: number) => {
  const lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 };
  let roman = '';
  for (const key of Object.keys(lookup)) {
    const value = lookup[key];
    while (num >= value) {
      roman += key;
      num -= value;
    }
  }
  return roman;
}

export const truncate = (value: string, len: number, onWordBreak = true) => {
  if (value.length <= len) return value;
  let short = value.slice(0, len - 1);
  if (onWordBreak) {
    short = short.slice(0, short.lastIndexOf(' '));
  }
  return short + '…';
};