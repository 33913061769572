import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { FeedbackDto } from '../model/dto/Feedback.model';


const initialState: EntityState<void> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  updateSuccess: false,
}

// Actions
export const sendFeedback = createAsyncThunk(
  'feedback/send_feedback',
  async (feedback: FeedbackDto) => {
    const requestUrl = `api/feedback`;
    return axios.post<FeedbackDto>(requestUrl, feedback);
  },
  { serializeError: serializeAxiosError }
);

// Slice
export const FeedbackSlice = createEntitySlice({
  name: 'feedback',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(sendFeedback), (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addMatcher(isPending(sendFeedback), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = FeedbackSlice.actions;

// Reducer
export default FeedbackSlice.reducer;
