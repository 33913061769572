import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { FarmUserDto, defaultValue } from '../model/dto/FarmUser.model';

const initialState: EntityState<FarmUserDto> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

// Actions
export const getFarmUsers = createAsyncThunk(
  'farmUsers/get_farm_users',
  async ({ id, includeInactive }: { id: string | number; includeInactive?: boolean }) => {
    const requestUrl = `api/memberships/farm-users/${id}`;
    return axios.get<FarmUserDto[]>(requestUrl, {
      params: {
        includeInactive,
      },
    });
  },
  { serializeError: serializeAxiosError }
);

// Slice
export const AccessSlice = createEntitySlice({
  name: 'farmUsers',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getFarmUsers), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isPending(getFarmUsers), (state, action) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = AccessSlice.actions;

// Reducer
export default AccessSlice.reducer;
