import dayjs from 'dayjs';
import { IEvent } from 'app/shared/model/event.model';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { IAppUser } from 'app/shared/model/app-user.model';
import { IHerd } from 'app/shared/model/herd.model';
import { IForm } from 'app/shared/model/form.model';

export interface IAction {
  id?: number;
  description?: string | null;
  title?: string | null;
  daysAfterParent?: number | null;
  window?: number | null;
  startTime?: string | null;
  completedDate?: string | null;
  completedBy?: string | null;
  ignored?: boolean | null;
  parent?: IAction | null;
  task?: IEvent;
  contactEmail?: string;
  assignedOrganisation?: IOrganisation | null;
  assignedUser?: IAppUser | null;
  herds?: IHerd[] | null;
  child?: IAction | null;
  form?: IForm | null;
  calculatedStartTime?: string | null;
  farmId?: number | null;
  tags?: string |null;
}

export const defaultValue: Readonly<IAction> = {
  ignored: false,
};
