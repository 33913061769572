import { showLoading, hideLoading, MiddlewareConfig } from 'react-redux-loading-bar'

const defaultTypeSuffixes = ['PENDING', 'FULFILLED', 'REJECTED'];


/**
 * Customised implementation of loadingBarMiddleware from `react-redux-loading-bar`.
 * Filters out additional actions by type.
 */
export const customLoadingBarMiddleware = (config: MiddlewareConfig = {}) => {
  const promiseTypeSuffixes = config.promiseTypeSuffixes || defaultTypeSuffixes;
  const scope = config.scope || 'default';

  return ({ dispatch }) => (next) => (action) => {
    const notificationsUnreadCountRegex = /^notifications\/fetch_unread_count/;

    if (action.type && !action.type.match(notificationsUnreadCountRegex)) {
      const [PENDING, FULFILLED, REJECTED] = promiseTypeSuffixes;

      const isPending = new RegExp(`${PENDING}$`, 'gi');
      const isFulfilled = new RegExp(`${FULFILLED}$`, 'gi');
      const isRejected = new RegExp(`${REJECTED}$`, 'gi');

      const actionScope = (action.meta && action.meta.scope) || action.scope || scope;

      if (action.type.match(isPending)) {
        dispatch(showLoading(actionScope));
      } else if (action.type.match(isFulfilled) || action.type.match(isRejected)) {
        dispatch(hideLoading(actionScope));
      }
    }

    return next(action);
  }
}