import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

interface Props {
  notFound?: boolean;
  backLink?: string;
  loadingText?: string;
}

export const LoadingSpinner = ({ notFound, backLink, loadingText }: Props) => {

  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center' style={{ paddingTop: '20%' }}>
      {notFound && (
        <>
          <span>{`We're sorry, something went wrong.`}</span>
          {backLink && (
            <Link to={backLink}>
              <Button color='primary' className='mt-2'>Go Back</Button>
            </Link>
          )}
        </>
      )}
      {!notFound && (
        <>
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          <span>
            {loadingText ?? 'Loading'}
          </span>
        </>
      )}
    </div>
  );
}
