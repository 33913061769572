export enum UserRole {
  FARMER = 'FARMER',

  VET = 'VET',

  VET_ADMIN = 'VET_ADMIN',

  NUTRITIONIST = 'NUTRITIONIST',

  CONSULTANT = 'CONSULTANT',

  FARM_STAFF = 'FARM_STAFF',
}

export const isFarmerRole = (role: UserRole) => role === UserRole.FARMER || role === UserRole.FARM_STAFF;

export const isVetRole = (role: UserRole) => role === UserRole.VET || role === UserRole.VET_ADMIN;

export const isConsultantRole = (role: UserRole) => role === UserRole.CONSULTANT || role === UserRole.NUTRITIONIST;
