import React, {useEffect} from 'react';
import {ValidatedForm, isEmail} from 'react-jhipster';
import {Button, Alert, Col} from 'reactstrap';
import {toast} from 'react-toastify';

import {handlePasswordResetInit, reset} from '../password-reset.reducer';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {UnauthenticatedPageWrapper} from "app/modules/account/UnauthenticatedPageWrapper";
import {LhmInput} from "app/shared/layout/form/LhmInput";

export const PasswordResetInit = () => {
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const handleValidSubmit = ({email}) => {
    dispatch(handlePasswordResetInit(email));
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  return (
    <UnauthenticatedPageWrapper imgSrc='/content/images/hero/sheep.jpg' imgAlt='A sheep in the Scottish mountains'>
      <h2>Reset your password</h2>
      <Col md="12" className='align-items-center'>
        <ValidatedForm onSubmit={handleValidSubmit}>
          <Alert color="warning">
            <p>Enter the email address you used to register</p>
          </Alert>
          <LhmInput
            name="email"
            label="Email"
            placeholder={'Your email'}
            type="email"
            validate={{
              required: {value: true, message: 'Your email is required.'},
              minLength: {value: 5, message: 'Your email is required to be at least 5 characters.'},
              maxLength: {value: 254, message: 'Your email cannot be longer than 254 characters.'},
              validate: v => isEmail(v) || 'Your email is invalid.',
            }}
            data-cy="emailResetPassword"
          />
          <div className="mt-1">&nbsp;</div>
          <Button color="primary" type="submit" data-cy="submit" className='w-100'>
            Reset password
          </Button>
        </ValidatedForm>
      </Col>
    </UnauthenticatedPageWrapper>
  );
};

export default PasswordResetInit;
