import { IAction } from "../action.model";
import { IForm } from "../form.model";
import { ITarget } from "../target.model";

export interface IUpcomingItem {
  title: string;
  form?: IForm;
  action?: IAction;
  target?: ITarget;
  date: string;
}

export const defaultValue: IUpcomingItem = {
  title: null,
  form: null,
  action: null,
  target: null,
  date: null,
}