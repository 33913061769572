import { IOrganisation } from 'app/shared/model/organisation.model';
import { IMembershipContract } from 'app/shared/model/membership-contract.model';

export interface IFarmDetails {
  id?: number;
  cphNumber?: string | null;
  name?: string;
  addressStreet?: string;
  addressStreet2?: string | null;
  addressCity?: string;
  addressCounty?: string | null;
  addressPostcode?: string;
  companyNumber?: string | null;
  contactName?: string;
  contactNumber?: string;
  contactNumberMobile?: string | null;
  contactNumberAlt?: string | null;
  emailAddress?: string;
  emailAddressAlt?: string | null;
  webPage?: string | null;
  country?: string | null;
  assuranceScheme?: string | null;
  isFake?: boolean;
  farm?: IOrganisation | null;
  membershipContracts?: IMembershipContract[] | null;
}

export const defaultValue: Readonly<IFarmDetails> = {
  isFake: false,
};

export enum AssuranceSchemes {
  WBLP = "WBLP",
  QMS = "QMS",
  RED_TRACTOR = "RED_TRACTOR",
  LMC_NI = "LMC_NI",
}

export const assuranceSchemeDescriptions: Record<AssuranceSchemes, string> = {
  [AssuranceSchemes.QMS] : 'Quality Meat Scotland',
  [AssuranceSchemes.RED_TRACTOR] : 'Red Tractor',
  [AssuranceSchemes.WBLP] : 'Welsh Lamb & Beef Producers',
  [AssuranceSchemes.LMC_NI] : ' Livestock & Meat Commission for Northern Ireland',
}

export const formatAssuranceScheme = (value: string) => {
  return value.split(',').map(v => assuranceSchemeDescriptions[v] ?? v).join(', ');
}

export const formatCphNumbersForDisplay = (cphNumbers: string) => cphNumbers?.split(',').map(c => c.trim()).join(', ');
