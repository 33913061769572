import React, {useState, useEffect} from 'react';
import {ValidatedForm, isEmail} from 'react-jhipster';
import {Col, Alert} from 'reactstrap';
import {toast} from 'react-toastify';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {handleRegister, reset} from './register.reducer';
import {UnauthenticatedPageWrapper} from "app/modules/account/UnauthenticatedPageWrapper";
import {Link, useHistory} from "react-router-dom";
import {useForm} from 'react-hook-form';
import {UserRole} from 'app/shared/model/enumerations/user-role.model';
import MultiStageFormWrapper from 'app/shared/layout/form/MultiStageFormWrapper';
import { FormOptions } from 'app/shared/layout/form/formUtils';
import { LhmChoiceInputs, LhmInput, LhmPasswordInput } from 'app/shared/layout/form/LhmInput';
import { WebLink } from 'app/components/WebLink';

const roleOptions: FormOptions<UserRole>[] = [
  {displayName: 'Farmer', datacy: 'roleFarmer', value: UserRole.FARMER},
  {displayName: 'Vet', datacy: 'roleVet', value: UserRole.VET},
  {displayName: 'Consultant', datacy: 'roleConsultant', value: UserRole.CONSULTANT},
]

export const RegisterPage = () => {
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();
  const history = useHistory();
  const success = useAppSelector(state=>state.register.registrationSuccess);
  const loading = useAppSelector(state=>state.register.loading);
  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const {
    handleSubmit, register, formState: {errors, touchedFields}, trigger, clearErrors,
  } = useForm({mode: 'onTouched'});


  const handleValidSubmit = ({firstName, lastName, role, email, firstPassword}) => {
    dispatch(handleRegister({firstName, lastName, login: email, email, password: firstPassword, langKey: 'en', role}));
  };

  const handleRegisterSubmit = e => {
    handleSubmit(handleValidSubmit)(e);
  }

  const updatePassword = event => setPassword(event.target.value);
  const successMessage = useAppSelector(state => state.register.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      const timeout = setTimeout(()=> {
        history.push('/login');
      },2000);
      return () => {
        clearTimeout(timeout);
      }
    }
  }, [successMessage, history]);


  return (
    <UnauthenticatedPageWrapper
      imgSrc='/content/images/hero/highland-cow.jpg'
      imgAlt='A Highland cow on a rocky hillside'
    >
      <h2 id="register-title" data-cy="registerTitle">
        Registration
      </h2>
      <Col md="12" className='align-items-center'>
        <ValidatedForm id="register-form" onSubmit={handleRegisterSubmit} autoComplete='off'>
          <MultiStageFormWrapper
            submitBtnText='Register'
            submitBtnId='register-submit'
            trigger={trigger}
            clearErrors={clearErrors}
            disableButton={success || loading}
          >
            <>
              <LhmInput
                name="firstName"
                label="First name"
                placeholder={'Your first name'}
                validate={{
                  required: {value: true, message: 'Your first name is required.'},
                  minLength: {value: 1, message: 'Your first name is required to be at least 1 character.'},
                  maxLength: {value: 50, message: 'Your first name cannot be longer than 50 characters.'},
                }}
                data-cy="firstName"
                register={register}
                error={errors.firstName}
                isTouched={touchedFields.firstName}
              />
              <LhmInput
                name="lastName"
                label="Last name"
                placeholder={'Your last name'}
                validate={{
                  required: {value: true, message: 'Your last name is required.'},
                  minLength: {value: 1, message: 'Your last name is required to be at least 1 character.'},
                  maxLength: {value: 50, message: 'Your last name cannot be longer than 50 characters.'},
                }}
                data-cy="lastName"
                register={register}
                error={errors.lastName}
                isTouched={touchedFields.lastName}
              />
              <LhmChoiceInputs
                name='role'
                options={roleOptions}
                type='radio'
                horizontal
                label='Your role'
                validate={{
                  required: 'Your role is required.'
                }}
                register={register}
                error={errors.role}
                isTouched={touchedFields.role}
              />
            </>
            <>
              <LhmInput
                name="email"
                label="Email"
                placeholder={'Your email'}
                type="email"
                validate={{
                  required: {value: true, message: 'Your email is required.'},
                  minLength: {value: 5, message: 'Your email is required to be at least 5 characters.'},
                  maxLength: {value: 254, message: 'Your email cannot be longer than 254 characters.'},
                  validate: v => isEmail(v) || 'Your email is invalid.',
                }}
                data-cy="email"
                register={register}
                error={errors.email}
                isTouched={touchedFields.email}
              />
              <LhmPasswordInput
                name="firstPassword"
                label="New password"
                placeholder={'New password'}
                onChange={updatePassword}
                validate={{
                  required: {value: true, message: 'Your password is required.'},
                  minLength: {value: 4, message: 'Your password is required to be at least 4 characters.'},
                  maxLength: {value: 50, message: 'Your password cannot be longer than 50 characters.'},
                }}
                data-cy="firstPassword"
                register={register}
                error={errors.firstPassword}
                isTouched={touchedFields.firstPassword}
              />
              <PasswordStrengthBar password={password}/>
              <LhmPasswordInput
                name="secondPassword"
                label="New password confirmation"
                placeholder="Confirm the new password"
                validate={{
                  required: {value: true, message: 'Your confirmation password is required.'},
                  minLength: {value: 4, message: 'Your confirmation password is required to be at least 4 characters.'},
                  maxLength: {value: 50, message: 'Your confirmation password cannot be longer than 50 characters.'},
                  validate: v => v === password || 'The password and its confirmation do not match!',
                }}
                data-cy="secondPassword"
                register={register}
                error={errors.secondPassword}
                isTouched={touchedFields.secondPassword}
              />

              <div className="mt-3 mb-2">
                <small>
                  <WebLink url='https://herdplan.co.uk/terms-of-use' text='HerdPlan terms of use' />
                </small>
              </div>
              <LhmChoiceInputs
                datacy='legal-agree'
                name='legalAgree'
                options={[{ datacy: 'legalAgree', displayName: 'I have read and agree to the terms of use.', value: 'true' }]}
                type='checkbox'
                register={register}
                error={errors.legalAgree}
                isTouched={touchedFields.legalAgree}
                validate={{ required: 'Confirmation is required.' }}
              />
            </>
          </MultiStageFormWrapper>
          <div className="mt-1">&nbsp;</div>
          <Alert color="warning">
            <span>Already have an account? <Link to='/login'>Sign in</Link></span>
          </Alert>
        </ValidatedForm>
      </Col>
    </UnauthenticatedPageWrapper>
  );
};

export default RegisterPage;
